.pds-fadeout {
  animation: fadeout 0.2s 1 forwards;
  pointer-events: none;
}

@keyframes fadeout {
  to {
    opacity: 0;
  }
}
@keyframes rotateThreeSixty {
  from {
    transform: rotate(0deg) translateZ(0);
  }
  to {
    transform: rotate(360deg) translateZ(0);
  }
}
:root {
  --pds-white: #fff;
  --pds-warm-white: #fff8f1;
  --pds-charcoal: #1d1e1c;
  --pds-gray-100: #eee;
  --pds-gray-150: #ddd;
  --pds-gray-200: #c6c6c6;
  --pds-gray-300: #bbb;
  --pds-gray-400: #a5a5a4;
  --pds-gray-500: #8d8e8d;
  --pds-gray-600: #737472;
  --pds-gray-700: #616261;
  --pds-gray-800: #4a4b49;
  --pds-gray-900: #343533;
  --pds-gray-alpha-100: rgba(29, 30, 28, 0.07);
  --pds-gray-alpha-150: rgba(29, 30, 28, 0.15);
  --pds-gray-alpha-200: rgba(29, 30, 28, 0.25);
  --pds-gray-alpha-300: rgba(29, 30, 28, 0.3);
  --pds-gray-alpha-400: rgba(29, 30, 28, 0.4);
  --pds-gray-alpha-500: rgba(29, 30, 28, 0.5);
  --pds-gray-alpha-600: rgba(29, 30, 28, 0.62);
  --pds-gray-alpha-700: rgba(29, 30, 28, 0.7);
  --pds-gray-alpha-800: rgba(29, 30, 28, 0.8);
  --pds-gray-alpha-900: rgba(29, 30, 28, 0.9);
  --pds-orange-100: #ffe7d9;
  --pds-orange-200: #ffc5a3;
  --pds-orange-300: #ffa26c;
  --pds-orange-400: #ff8036;
  --pds-orange-500: #fa5d00;
  --pds-orange-600: #df4b00;
  --pds-orange-700: #bf3b00;
  --pds-green-100: #daefdc;
  --pds-green-200: #a8d6ad;
  --pds-green-300: #76bc82;
  --pds-green-400: #46a159;
  --pds-green-500: #188433;
  --pds-green-600: #11742a;
  --pds-green-700: #0b6221;
  --pds-blue-100: #deedfd;
  --pds-blue-200: #b1d0f8;
  --pds-blue-300: #86b1f1;
  --pds-blue-400: #5d8fe8;
  --pds-blue-500: #376bdd;
  --pds-blue-600: #2a59c1;
  --pds-blue-700: #1e48a3;
  --pds-red-100: #fde2dd;
  --pds-red-200: #f7b7ae;
  --pds-red-300: #ef8b81;
  --pds-red-400: #e55d57;
  --pds-red-500: #d92f2f;
  --pds-red-600: #be2923;
  --pds-red-700: #a12318;
  --pds-yellow-100: #fff1cd;
  --pds-yellow-200: #ffe5a8;
  --pds-yellow-300: #ffda84;
  --pds-yellow-400: #ffcf60;
  --pds-yellow-500: #ffc33c;
  --pds-yellow-600: #d99c22;
  --pds-yellow-700: #b3730f;
  --pds-gray: rgba(29, 30, 28, 0.62);
  --pds-orange: #fa5d00;
  --pds-green: #188433;
  --pds-blue: #376bdd;
  --pds-red: #d92f2f;
  --pds-yellow: #ffc33c;
  --pds-text: #1d1e1c;
  --pds-text-muted: rgba(29, 30, 28, 0.7);
}

@media (prefers-reduced-motion: no-preference) {
  .pds-clock-running-minute-hand {
    animation: rotateThreeSixty 3s infinite linear;
    transform-origin: 50% 50%;
  }
  .pds-clock-running-hour-hand {
    animation: rotateThreeSixty 30s infinite linear;
    transform-origin: 50% 50%;
  }
}
/* stylelint-disable scss/dollar-variable-pattern */
/* stylelint-enable scss/dollar-variable-pattern */
:root {
  --pds-space-0: 0;
  --pds-space-xs: 4px;
  --pds-space-sm: 8px;
  --pds-space-md: 16px;
  --pds-space-lg: 24px;
  --pds-space-xl: 32px;
  --pds-space-xxl: 64px;
}

.pds-color-inherit {
  color: inherit !important;
}

.pds-color-text {
  color: #1d1e1c !important;
}

.pds-color-muted {
  color: rgba(29, 30, 28, 0.7) !important;
}

.pds-color-gray {
  color: rgba(29, 30, 28, 0.62) !important;
}

.pds-color-blue {
  color: #376bdd !important;
}

.pds-color-green {
  color: #0b6221 !important;
}

.pds-color-yellow {
  color: #b3730f !important;
}

.pds-color-orange {
  color: #df4b00 !important;
}

.pds-color-red {
  color: #d92f2f !important;
}

.pds-color-white {
  color: #fff !important;
}

.pds-data-fill-blue {
  background: #376bdd !important;
}

.pds-data-fill-blue-lt {
  background: #86b1f1 !important;
}

.pds-data-fill-green {
  background: #188433 !important;
}

.pds-data-fill-green-lt {
  background: #76bc82 !important;
}

.pds-data-fill-red {
  background: #d92f2f !important;
}

.pds-data-fill-red-lt {
  background: #ef8b81 !important;
}

.pds-data-fill-orange {
  background: #fa5d00 !important;
}

.pds-data-fill-orange-lt {
  background: #ffa26c !important;
}

.pds-data-fill-purple {
  background: #8342d9 !important;
}

.pds-data-fill-purple-lt {
  background: #d45665 !important;
}

.pds-data-fill-yellow {
  background: #ffc33c !important;
}

.pds-data-fill-aqua {
  background: #0eb5ce !important;
}

.pds-data-fill-gray {
  background: #616261 !important;
}

.pds-flex,
.pds-display-flex {
  display: flex !important;
}

.pds-inline-flex,
.pds-display-inline-flex {
  display: inline-flex !important;
}

.pds-justify-start {
  justify-content: flex-start !important;
}

.pds-justify-end {
  justify-content: flex-end !important;
}

.pds-justify-center {
  justify-content: center !important;
}

.pds-justify-between {
  justify-content: space-between !important;
}

.pds-justify-around {
  justify-content: space-around !important;
}

.pds-items-start {
  align-items: flex-start !important;
}

.pds-items-end {
  align-items: flex-end !important;
}

.pds-items-center {
  align-items: center !important;
}

.pds-items-baseline {
  align-items: baseline !important;
}

.pds-items-stretch {
  align-items: stretch !important;
}

.pds-self-auto {
  align-self: auto !important;
}

.pds-self-start {
  align-self: flex-start !important;
}

.pds-self-end {
  align-self: flex-end !important;
}

.pds-self-center {
  align-self: center !important;
}

.pds-self-stretch {
  align-self: stretch !important;
}

.pds-flex-fill {
  flex: 1 !important;
}

.pds-flex-auto {
  flex: auto !important;
}

.pds-flex-no-grow {
  flex-grow: 0 !important;
}

.pds-flex-no-shrink {
  flex-shrink: 0 !important;
}

.pds-flex-wrap {
  flex-wrap: wrap !important;
}

.pds-flex-nowrap {
  flex-wrap: revert !important;
}

.pds-gap-lg {
  gap: 24px !important;
}

.pds-gap-md {
  gap: 16px !important;
}

.pds-gap-sm {
  gap: 8px !important;
}

.pds-gap-xs {
  gap: 4px !important;
}

.pds-button-input-height-xl {
  min-height: 44px !important;
}

.pds-button-input-height-lg {
  min-height: 40px !important;
}

.pds-button-input-height-md {
  min-height: 34px !important;
}

.pds-button-input-height-sm {
  min-height: 29px !important;
}

.pds-button-input-height-xs {
  min-height: 25px !important;
}

.pds-block,
.pds-display-block {
  display: block !important;
}

.pds-inline-block,
.pds-display-inline-block {
  display: inline-block !important;
}

.pds-display-contents {
  display: contents !important;
}

.pds-valign-top {
  vertical-align: top !important;
}

.pds-valign-middle {
  vertical-align: middle !important;
}

.pds-valign-bottom {
  vertical-align: bottom !important;
}

.pds-valign-baseline {
  vertical-align: baseline !important;
}

.pds-fl-left {
  float: left !important;
}

.pds-fl-right {
  float: right !important;
}

.pds-position-relative {
  position: relative !important;
}

.pds-clearfix::after {
  clear: both;
  content: "";
  display: block;
}

.pds-w-full {
  width: 100% !important;
}

@media (min-width: 320px) {
  .pds-w\@xs-full {
    width: 100% !important;
  }
}
@media (min-width: 320px) {
  .pds-hide\@xs {
    display: none !important;
  }
}
@media (max-width: 319px) {
  .pds-show\@xs {
    display: none !important;
  }
}
@media (min-width: 544px) {
  .pds-w\@sm-full {
    width: 100% !important;
  }
}
@media (min-width: 544px) {
  .pds-hide\@sm {
    display: none !important;
  }
}
@media (max-width: 543px) {
  .pds-show\@sm {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .pds-w\@md-full {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .pds-hide\@md {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .pds-show\@md {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .pds-w\@lg-full {
    width: 100% !important;
  }
}
@media (min-width: 992px) {
  .pds-hide\@lg {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .pds-show\@lg {
    display: none !important;
  }
}
@media (min-width: 1216px) {
  .pds-w\@xl-full {
    width: 100% !important;
  }
}
@media (min-width: 1216px) {
  .pds-hide\@xl {
    display: none !important;
  }
}
@media (max-width: 1215px) {
  .pds-show\@xl {
    display: none !important;
  }
}
.pds-m-0 {
  margin: 0 !important;
}

.pds-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.pds-my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.pds-mt-0 {
  margin-top: 0 !important;
}

.pds-mr-0 {
  margin-right: 0 !important;
}

.pds-mb-0 {
  margin-bottom: 0 !important;
}

.pds-ml-0 {
  margin-left: 0 !important;
}

.pds-m-xs {
  margin: 4px !important;
}

.pds-mx-xs {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.pds-my-xs {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
}

.pds-mt-xs {
  margin-top: 4px !important;
}

.pds-mr-xs {
  margin-right: 4px !important;
}

.pds-mb-xs {
  margin-bottom: 4px !important;
}

.pds-ml-xs {
  margin-left: 4px !important;
}

.pds-m-sm {
  margin: 8px !important;
}

.pds-mx-sm {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.pds-my-sm {
  margin-bottom: 8px !important;
  margin-top: 8px !important;
}

.pds-mt-sm {
  margin-top: 8px !important;
}

.pds-mr-sm {
  margin-right: 8px !important;
}

.pds-mb-sm {
  margin-bottom: 8px !important;
}

.pds-ml-sm {
  margin-left: 8px !important;
}

.pds-m-md {
  margin: 16px !important;
}

.pds-mx-md {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.pds-my-md {
  margin-bottom: 16px !important;
  margin-top: 16px !important;
}

.pds-mt-md {
  margin-top: 16px !important;
}

.pds-mr-md {
  margin-right: 16px !important;
}

.pds-mb-md {
  margin-bottom: 16px !important;
}

.pds-ml-md {
  margin-left: 16px !important;
}

.pds-m-lg {
  margin: 24px !important;
}

.pds-mx-lg {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.pds-my-lg {
  margin-bottom: 24px !important;
  margin-top: 24px !important;
}

.pds-mt-lg {
  margin-top: 24px !important;
}

.pds-mr-lg {
  margin-right: 24px !important;
}

.pds-mb-lg {
  margin-bottom: 24px !important;
}

.pds-ml-lg {
  margin-left: 24px !important;
}

.pds-m-xl {
  margin: 32px !important;
}

.pds-mx-xl {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.pds-my-xl {
  margin-bottom: 32px !important;
  margin-top: 32px !important;
}

.pds-mt-xl {
  margin-top: 32px !important;
}

.pds-mr-xl {
  margin-right: 32px !important;
}

.pds-mb-xl {
  margin-bottom: 32px !important;
}

.pds-ml-xl {
  margin-left: 32px !important;
}

.pds-m-xxl {
  margin: 64px !important;
}

.pds-mx-xxl {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.pds-my-xxl {
  margin-bottom: 64px !important;
  margin-top: 64px !important;
}

.pds-mt-xxl {
  margin-top: 64px !important;
}

.pds-mr-xxl {
  margin-right: 64px !important;
}

.pds-mb-xxl {
  margin-bottom: 64px !important;
}

.pds-ml-xxl {
  margin-left: 64px !important;
}

@media (min-width: 320px) {
  .pds-m\@xs-0 {
    margin: 0 !important;
  }
  .pds-mx\@xs-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .pds-my\@xs-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .pds-mt\@xs-0 {
    margin-top: 0 !important;
  }
  .pds-mr\@xs-0 {
    margin-right: 0 !important;
  }
  .pds-mb\@xs-0 {
    margin-bottom: 0 !important;
  }
  .pds-ml\@xs-0 {
    margin-left: 0 !important;
  }
  .pds-m\@xs-xs {
    margin: 4px !important;
  }
  .pds-mx\@xs-xs {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .pds-my\@xs-xs {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .pds-mt\@xs-xs {
    margin-top: 4px !important;
  }
  .pds-mr\@xs-xs {
    margin-right: 4px !important;
  }
  .pds-mb\@xs-xs {
    margin-bottom: 4px !important;
  }
  .pds-ml\@xs-xs {
    margin-left: 4px !important;
  }
  .pds-m\@xs-sm {
    margin: 8px !important;
  }
  .pds-mx\@xs-sm {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .pds-my\@xs-sm {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .pds-mt\@xs-sm {
    margin-top: 8px !important;
  }
  .pds-mr\@xs-sm {
    margin-right: 8px !important;
  }
  .pds-mb\@xs-sm {
    margin-bottom: 8px !important;
  }
  .pds-ml\@xs-sm {
    margin-left: 8px !important;
  }
  .pds-m\@xs-md {
    margin: 16px !important;
  }
  .pds-mx\@xs-md {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .pds-my\@xs-md {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .pds-mt\@xs-md {
    margin-top: 16px !important;
  }
  .pds-mr\@xs-md {
    margin-right: 16px !important;
  }
  .pds-mb\@xs-md {
    margin-bottom: 16px !important;
  }
  .pds-ml\@xs-md {
    margin-left: 16px !important;
  }
  .pds-m\@xs-lg {
    margin: 24px !important;
  }
  .pds-mx\@xs-lg {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .pds-my\@xs-lg {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .pds-mt\@xs-lg {
    margin-top: 24px !important;
  }
  .pds-mr\@xs-lg {
    margin-right: 24px !important;
  }
  .pds-mb\@xs-lg {
    margin-bottom: 24px !important;
  }
  .pds-ml\@xs-lg {
    margin-left: 24px !important;
  }
  .pds-m\@xs-xl {
    margin: 32px !important;
  }
  .pds-mx\@xs-xl {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .pds-my\@xs-xl {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .pds-mt\@xs-xl {
    margin-top: 32px !important;
  }
  .pds-mr\@xs-xl {
    margin-right: 32px !important;
  }
  .pds-mb\@xs-xl {
    margin-bottom: 32px !important;
  }
  .pds-ml\@xs-xl {
    margin-left: 32px !important;
  }
  .pds-m\@xs-xxl {
    margin: 64px !important;
  }
  .pds-mx\@xs-xxl {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .pds-my\@xs-xxl {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .pds-mt\@xs-xxl {
    margin-top: 64px !important;
  }
  .pds-mr\@xs-xxl {
    margin-right: 64px !important;
  }
  .pds-mb\@xs-xxl {
    margin-bottom: 64px !important;
  }
  .pds-ml\@xs-xxl {
    margin-left: 64px !important;
  }
}
@media (min-width: 544px) {
  .pds-m\@sm-0 {
    margin: 0 !important;
  }
  .pds-mx\@sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .pds-my\@sm-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .pds-mt\@sm-0 {
    margin-top: 0 !important;
  }
  .pds-mr\@sm-0 {
    margin-right: 0 !important;
  }
  .pds-mb\@sm-0 {
    margin-bottom: 0 !important;
  }
  .pds-ml\@sm-0 {
    margin-left: 0 !important;
  }
  .pds-m\@sm-xs {
    margin: 4px !important;
  }
  .pds-mx\@sm-xs {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .pds-my\@sm-xs {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .pds-mt\@sm-xs {
    margin-top: 4px !important;
  }
  .pds-mr\@sm-xs {
    margin-right: 4px !important;
  }
  .pds-mb\@sm-xs {
    margin-bottom: 4px !important;
  }
  .pds-ml\@sm-xs {
    margin-left: 4px !important;
  }
  .pds-m\@sm-sm {
    margin: 8px !important;
  }
  .pds-mx\@sm-sm {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .pds-my\@sm-sm {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .pds-mt\@sm-sm {
    margin-top: 8px !important;
  }
  .pds-mr\@sm-sm {
    margin-right: 8px !important;
  }
  .pds-mb\@sm-sm {
    margin-bottom: 8px !important;
  }
  .pds-ml\@sm-sm {
    margin-left: 8px !important;
  }
  .pds-m\@sm-md {
    margin: 16px !important;
  }
  .pds-mx\@sm-md {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .pds-my\@sm-md {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .pds-mt\@sm-md {
    margin-top: 16px !important;
  }
  .pds-mr\@sm-md {
    margin-right: 16px !important;
  }
  .pds-mb\@sm-md {
    margin-bottom: 16px !important;
  }
  .pds-ml\@sm-md {
    margin-left: 16px !important;
  }
  .pds-m\@sm-lg {
    margin: 24px !important;
  }
  .pds-mx\@sm-lg {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .pds-my\@sm-lg {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .pds-mt\@sm-lg {
    margin-top: 24px !important;
  }
  .pds-mr\@sm-lg {
    margin-right: 24px !important;
  }
  .pds-mb\@sm-lg {
    margin-bottom: 24px !important;
  }
  .pds-ml\@sm-lg {
    margin-left: 24px !important;
  }
  .pds-m\@sm-xl {
    margin: 32px !important;
  }
  .pds-mx\@sm-xl {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .pds-my\@sm-xl {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .pds-mt\@sm-xl {
    margin-top: 32px !important;
  }
  .pds-mr\@sm-xl {
    margin-right: 32px !important;
  }
  .pds-mb\@sm-xl {
    margin-bottom: 32px !important;
  }
  .pds-ml\@sm-xl {
    margin-left: 32px !important;
  }
  .pds-m\@sm-xxl {
    margin: 64px !important;
  }
  .pds-mx\@sm-xxl {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .pds-my\@sm-xxl {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .pds-mt\@sm-xxl {
    margin-top: 64px !important;
  }
  .pds-mr\@sm-xxl {
    margin-right: 64px !important;
  }
  .pds-mb\@sm-xxl {
    margin-bottom: 64px !important;
  }
  .pds-ml\@sm-xxl {
    margin-left: 64px !important;
  }
}
@media (min-width: 768px) {
  .pds-m\@md-0 {
    margin: 0 !important;
  }
  .pds-mx\@md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .pds-my\@md-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .pds-mt\@md-0 {
    margin-top: 0 !important;
  }
  .pds-mr\@md-0 {
    margin-right: 0 !important;
  }
  .pds-mb\@md-0 {
    margin-bottom: 0 !important;
  }
  .pds-ml\@md-0 {
    margin-left: 0 !important;
  }
  .pds-m\@md-xs {
    margin: 4px !important;
  }
  .pds-mx\@md-xs {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .pds-my\@md-xs {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .pds-mt\@md-xs {
    margin-top: 4px !important;
  }
  .pds-mr\@md-xs {
    margin-right: 4px !important;
  }
  .pds-mb\@md-xs {
    margin-bottom: 4px !important;
  }
  .pds-ml\@md-xs {
    margin-left: 4px !important;
  }
  .pds-m\@md-sm {
    margin: 8px !important;
  }
  .pds-mx\@md-sm {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .pds-my\@md-sm {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .pds-mt\@md-sm {
    margin-top: 8px !important;
  }
  .pds-mr\@md-sm {
    margin-right: 8px !important;
  }
  .pds-mb\@md-sm {
    margin-bottom: 8px !important;
  }
  .pds-ml\@md-sm {
    margin-left: 8px !important;
  }
  .pds-m\@md-md {
    margin: 16px !important;
  }
  .pds-mx\@md-md {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .pds-my\@md-md {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .pds-mt\@md-md {
    margin-top: 16px !important;
  }
  .pds-mr\@md-md {
    margin-right: 16px !important;
  }
  .pds-mb\@md-md {
    margin-bottom: 16px !important;
  }
  .pds-ml\@md-md {
    margin-left: 16px !important;
  }
  .pds-m\@md-lg {
    margin: 24px !important;
  }
  .pds-mx\@md-lg {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .pds-my\@md-lg {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .pds-mt\@md-lg {
    margin-top: 24px !important;
  }
  .pds-mr\@md-lg {
    margin-right: 24px !important;
  }
  .pds-mb\@md-lg {
    margin-bottom: 24px !important;
  }
  .pds-ml\@md-lg {
    margin-left: 24px !important;
  }
  .pds-m\@md-xl {
    margin: 32px !important;
  }
  .pds-mx\@md-xl {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .pds-my\@md-xl {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .pds-mt\@md-xl {
    margin-top: 32px !important;
  }
  .pds-mr\@md-xl {
    margin-right: 32px !important;
  }
  .pds-mb\@md-xl {
    margin-bottom: 32px !important;
  }
  .pds-ml\@md-xl {
    margin-left: 32px !important;
  }
  .pds-m\@md-xxl {
    margin: 64px !important;
  }
  .pds-mx\@md-xxl {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .pds-my\@md-xxl {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .pds-mt\@md-xxl {
    margin-top: 64px !important;
  }
  .pds-mr\@md-xxl {
    margin-right: 64px !important;
  }
  .pds-mb\@md-xxl {
    margin-bottom: 64px !important;
  }
  .pds-ml\@md-xxl {
    margin-left: 64px !important;
  }
}
@media (min-width: 992px) {
  .pds-m\@lg-0 {
    margin: 0 !important;
  }
  .pds-mx\@lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .pds-my\@lg-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .pds-mt\@lg-0 {
    margin-top: 0 !important;
  }
  .pds-mr\@lg-0 {
    margin-right: 0 !important;
  }
  .pds-mb\@lg-0 {
    margin-bottom: 0 !important;
  }
  .pds-ml\@lg-0 {
    margin-left: 0 !important;
  }
  .pds-m\@lg-xs {
    margin: 4px !important;
  }
  .pds-mx\@lg-xs {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .pds-my\@lg-xs {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .pds-mt\@lg-xs {
    margin-top: 4px !important;
  }
  .pds-mr\@lg-xs {
    margin-right: 4px !important;
  }
  .pds-mb\@lg-xs {
    margin-bottom: 4px !important;
  }
  .pds-ml\@lg-xs {
    margin-left: 4px !important;
  }
  .pds-m\@lg-sm {
    margin: 8px !important;
  }
  .pds-mx\@lg-sm {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .pds-my\@lg-sm {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .pds-mt\@lg-sm {
    margin-top: 8px !important;
  }
  .pds-mr\@lg-sm {
    margin-right: 8px !important;
  }
  .pds-mb\@lg-sm {
    margin-bottom: 8px !important;
  }
  .pds-ml\@lg-sm {
    margin-left: 8px !important;
  }
  .pds-m\@lg-md {
    margin: 16px !important;
  }
  .pds-mx\@lg-md {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .pds-my\@lg-md {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .pds-mt\@lg-md {
    margin-top: 16px !important;
  }
  .pds-mr\@lg-md {
    margin-right: 16px !important;
  }
  .pds-mb\@lg-md {
    margin-bottom: 16px !important;
  }
  .pds-ml\@lg-md {
    margin-left: 16px !important;
  }
  .pds-m\@lg-lg {
    margin: 24px !important;
  }
  .pds-mx\@lg-lg {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .pds-my\@lg-lg {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .pds-mt\@lg-lg {
    margin-top: 24px !important;
  }
  .pds-mr\@lg-lg {
    margin-right: 24px !important;
  }
  .pds-mb\@lg-lg {
    margin-bottom: 24px !important;
  }
  .pds-ml\@lg-lg {
    margin-left: 24px !important;
  }
  .pds-m\@lg-xl {
    margin: 32px !important;
  }
  .pds-mx\@lg-xl {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .pds-my\@lg-xl {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .pds-mt\@lg-xl {
    margin-top: 32px !important;
  }
  .pds-mr\@lg-xl {
    margin-right: 32px !important;
  }
  .pds-mb\@lg-xl {
    margin-bottom: 32px !important;
  }
  .pds-ml\@lg-xl {
    margin-left: 32px !important;
  }
  .pds-m\@lg-xxl {
    margin: 64px !important;
  }
  .pds-mx\@lg-xxl {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .pds-my\@lg-xxl {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .pds-mt\@lg-xxl {
    margin-top: 64px !important;
  }
  .pds-mr\@lg-xxl {
    margin-right: 64px !important;
  }
  .pds-mb\@lg-xxl {
    margin-bottom: 64px !important;
  }
  .pds-ml\@lg-xxl {
    margin-left: 64px !important;
  }
}
@media (min-width: 1216px) {
  .pds-m\@xl-0 {
    margin: 0 !important;
  }
  .pds-mx\@xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .pds-my\@xl-0 {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  .pds-mt\@xl-0 {
    margin-top: 0 !important;
  }
  .pds-mr\@xl-0 {
    margin-right: 0 !important;
  }
  .pds-mb\@xl-0 {
    margin-bottom: 0 !important;
  }
  .pds-ml\@xl-0 {
    margin-left: 0 !important;
  }
  .pds-m\@xl-xs {
    margin: 4px !important;
  }
  .pds-mx\@xl-xs {
    margin-left: 4px !important;
    margin-right: 4px !important;
  }
  .pds-my\@xl-xs {
    margin-bottom: 4px !important;
    margin-top: 4px !important;
  }
  .pds-mt\@xl-xs {
    margin-top: 4px !important;
  }
  .pds-mr\@xl-xs {
    margin-right: 4px !important;
  }
  .pds-mb\@xl-xs {
    margin-bottom: 4px !important;
  }
  .pds-ml\@xl-xs {
    margin-left: 4px !important;
  }
  .pds-m\@xl-sm {
    margin: 8px !important;
  }
  .pds-mx\@xl-sm {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .pds-my\@xl-sm {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
  }
  .pds-mt\@xl-sm {
    margin-top: 8px !important;
  }
  .pds-mr\@xl-sm {
    margin-right: 8px !important;
  }
  .pds-mb\@xl-sm {
    margin-bottom: 8px !important;
  }
  .pds-ml\@xl-sm {
    margin-left: 8px !important;
  }
  .pds-m\@xl-md {
    margin: 16px !important;
  }
  .pds-mx\@xl-md {
    margin-left: 16px !important;
    margin-right: 16px !important;
  }
  .pds-my\@xl-md {
    margin-bottom: 16px !important;
    margin-top: 16px !important;
  }
  .pds-mt\@xl-md {
    margin-top: 16px !important;
  }
  .pds-mr\@xl-md {
    margin-right: 16px !important;
  }
  .pds-mb\@xl-md {
    margin-bottom: 16px !important;
  }
  .pds-ml\@xl-md {
    margin-left: 16px !important;
  }
  .pds-m\@xl-lg {
    margin: 24px !important;
  }
  .pds-mx\@xl-lg {
    margin-left: 24px !important;
    margin-right: 24px !important;
  }
  .pds-my\@xl-lg {
    margin-bottom: 24px !important;
    margin-top: 24px !important;
  }
  .pds-mt\@xl-lg {
    margin-top: 24px !important;
  }
  .pds-mr\@xl-lg {
    margin-right: 24px !important;
  }
  .pds-mb\@xl-lg {
    margin-bottom: 24px !important;
  }
  .pds-ml\@xl-lg {
    margin-left: 24px !important;
  }
  .pds-m\@xl-xl {
    margin: 32px !important;
  }
  .pds-mx\@xl-xl {
    margin-left: 32px !important;
    margin-right: 32px !important;
  }
  .pds-my\@xl-xl {
    margin-bottom: 32px !important;
    margin-top: 32px !important;
  }
  .pds-mt\@xl-xl {
    margin-top: 32px !important;
  }
  .pds-mr\@xl-xl {
    margin-right: 32px !important;
  }
  .pds-mb\@xl-xl {
    margin-bottom: 32px !important;
  }
  .pds-ml\@xl-xl {
    margin-left: 32px !important;
  }
  .pds-m\@xl-xxl {
    margin: 64px !important;
  }
  .pds-mx\@xl-xxl {
    margin-left: 64px !important;
    margin-right: 64px !important;
  }
  .pds-my\@xl-xxl {
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
  .pds-mt\@xl-xxl {
    margin-top: 64px !important;
  }
  .pds-mr\@xl-xxl {
    margin-right: 64px !important;
  }
  .pds-mb\@xl-xxl {
    margin-bottom: 64px !important;
  }
  .pds-ml\@xl-xxl {
    margin-left: 64px !important;
  }
}
.pds-m-auto {
  margin: auto;
}

.pds-mr-auto {
  margin-right: auto;
}

.pds-ml-auto {
  margin-left: auto;
}

.pds-p-0 {
  padding: 0 !important;
}

.pds-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pds-py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.pds-pt-0 {
  padding-top: 0 !important;
}

.pds-pr-0 {
  padding-right: 0 !important;
}

.pds-pb-0 {
  padding-bottom: 0 !important;
}

.pds-pl-0 {
  padding-left: 0 !important;
}

.pds-p-xs {
  padding: 4px !important;
}

.pds-px-xs {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.pds-py-xs {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

.pds-pt-xs {
  padding-top: 4px !important;
}

.pds-pr-xs {
  padding-right: 4px !important;
}

.pds-pb-xs {
  padding-bottom: 4px !important;
}

.pds-pl-xs {
  padding-left: 4px !important;
}

.pds-p-sm {
  padding: 8px !important;
}

.pds-px-sm {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.pds-py-sm {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}

.pds-pt-sm {
  padding-top: 8px !important;
}

.pds-pr-sm {
  padding-right: 8px !important;
}

.pds-pb-sm {
  padding-bottom: 8px !important;
}

.pds-pl-sm {
  padding-left: 8px !important;
}

.pds-p-md {
  padding: 16px !important;
}

.pds-px-md {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.pds-py-md {
  padding-bottom: 16px !important;
  padding-top: 16px !important;
}

.pds-pt-md {
  padding-top: 16px !important;
}

.pds-pr-md {
  padding-right: 16px !important;
}

.pds-pb-md {
  padding-bottom: 16px !important;
}

.pds-pl-md {
  padding-left: 16px !important;
}

.pds-p-lg {
  padding: 24px !important;
}

.pds-px-lg {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.pds-py-lg {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.pds-pt-lg {
  padding-top: 24px !important;
}

.pds-pr-lg {
  padding-right: 24px !important;
}

.pds-pb-lg {
  padding-bottom: 24px !important;
}

.pds-pl-lg {
  padding-left: 24px !important;
}

.pds-p-xl {
  padding: 32px !important;
}

.pds-px-xl {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.pds-py-xl {
  padding-bottom: 32px !important;
  padding-top: 32px !important;
}

.pds-pt-xl {
  padding-top: 32px !important;
}

.pds-pr-xl {
  padding-right: 32px !important;
}

.pds-pb-xl {
  padding-bottom: 32px !important;
}

.pds-pl-xl {
  padding-left: 32px !important;
}

.pds-p-xxl {
  padding: 64px !important;
}

.pds-px-xxl {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.pds-py-xxl {
  padding-bottom: 64px !important;
  padding-top: 64px !important;
}

.pds-pt-xxl {
  padding-top: 64px !important;
}

.pds-pr-xxl {
  padding-right: 64px !important;
}

.pds-pb-xxl {
  padding-bottom: 64px !important;
}

.pds-pl-xxl {
  padding-left: 64px !important;
}

@media (min-width: 320px) {
  .pds-p\@xs-0 {
    padding: 0 !important;
  }
  .pds-px\@xs-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pds-py\@xs-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .pds-pt\@xs-0 {
    padding-top: 0 !important;
  }
  .pds-pr\@xs-0 {
    padding-right: 0 !important;
  }
  .pds-pb\@xs-0 {
    padding-bottom: 0 !important;
  }
  .pds-pl\@xs-0 {
    padding-left: 0 !important;
  }
  .pds-p\@xs-xs {
    padding: 4px !important;
  }
  .pds-px\@xs-xs {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pds-py\@xs-xs {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .pds-pt\@xs-xs {
    padding-top: 4px !important;
  }
  .pds-pr\@xs-xs {
    padding-right: 4px !important;
  }
  .pds-pb\@xs-xs {
    padding-bottom: 4px !important;
  }
  .pds-pl\@xs-xs {
    padding-left: 4px !important;
  }
  .pds-p\@xs-sm {
    padding: 8px !important;
  }
  .pds-px\@xs-sm {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pds-py\@xs-sm {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .pds-pt\@xs-sm {
    padding-top: 8px !important;
  }
  .pds-pr\@xs-sm {
    padding-right: 8px !important;
  }
  .pds-pb\@xs-sm {
    padding-bottom: 8px !important;
  }
  .pds-pl\@xs-sm {
    padding-left: 8px !important;
  }
  .pds-p\@xs-md {
    padding: 16px !important;
  }
  .pds-px\@xs-md {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .pds-py\@xs-md {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .pds-pt\@xs-md {
    padding-top: 16px !important;
  }
  .pds-pr\@xs-md {
    padding-right: 16px !important;
  }
  .pds-pb\@xs-md {
    padding-bottom: 16px !important;
  }
  .pds-pl\@xs-md {
    padding-left: 16px !important;
  }
  .pds-p\@xs-lg {
    padding: 24px !important;
  }
  .pds-px\@xs-lg {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pds-py\@xs-lg {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .pds-pt\@xs-lg {
    padding-top: 24px !important;
  }
  .pds-pr\@xs-lg {
    padding-right: 24px !important;
  }
  .pds-pb\@xs-lg {
    padding-bottom: 24px !important;
  }
  .pds-pl\@xs-lg {
    padding-left: 24px !important;
  }
  .pds-p\@xs-xl {
    padding: 32px !important;
  }
  .pds-px\@xs-xl {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .pds-py\@xs-xl {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .pds-pt\@xs-xl {
    padding-top: 32px !important;
  }
  .pds-pr\@xs-xl {
    padding-right: 32px !important;
  }
  .pds-pb\@xs-xl {
    padding-bottom: 32px !important;
  }
  .pds-pl\@xs-xl {
    padding-left: 32px !important;
  }
  .pds-p\@xs-xxl {
    padding: 64px !important;
  }
  .pds-px\@xs-xxl {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .pds-py\@xs-xxl {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .pds-pt\@xs-xxl {
    padding-top: 64px !important;
  }
  .pds-pr\@xs-xxl {
    padding-right: 64px !important;
  }
  .pds-pb\@xs-xxl {
    padding-bottom: 64px !important;
  }
  .pds-pl\@xs-xxl {
    padding-left: 64px !important;
  }
}
@media (min-width: 544px) {
  .pds-p\@sm-0 {
    padding: 0 !important;
  }
  .pds-px\@sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pds-py\@sm-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .pds-pt\@sm-0 {
    padding-top: 0 !important;
  }
  .pds-pr\@sm-0 {
    padding-right: 0 !important;
  }
  .pds-pb\@sm-0 {
    padding-bottom: 0 !important;
  }
  .pds-pl\@sm-0 {
    padding-left: 0 !important;
  }
  .pds-p\@sm-xs {
    padding: 4px !important;
  }
  .pds-px\@sm-xs {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pds-py\@sm-xs {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .pds-pt\@sm-xs {
    padding-top: 4px !important;
  }
  .pds-pr\@sm-xs {
    padding-right: 4px !important;
  }
  .pds-pb\@sm-xs {
    padding-bottom: 4px !important;
  }
  .pds-pl\@sm-xs {
    padding-left: 4px !important;
  }
  .pds-p\@sm-sm {
    padding: 8px !important;
  }
  .pds-px\@sm-sm {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pds-py\@sm-sm {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .pds-pt\@sm-sm {
    padding-top: 8px !important;
  }
  .pds-pr\@sm-sm {
    padding-right: 8px !important;
  }
  .pds-pb\@sm-sm {
    padding-bottom: 8px !important;
  }
  .pds-pl\@sm-sm {
    padding-left: 8px !important;
  }
  .pds-p\@sm-md {
    padding: 16px !important;
  }
  .pds-px\@sm-md {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .pds-py\@sm-md {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .pds-pt\@sm-md {
    padding-top: 16px !important;
  }
  .pds-pr\@sm-md {
    padding-right: 16px !important;
  }
  .pds-pb\@sm-md {
    padding-bottom: 16px !important;
  }
  .pds-pl\@sm-md {
    padding-left: 16px !important;
  }
  .pds-p\@sm-lg {
    padding: 24px !important;
  }
  .pds-px\@sm-lg {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pds-py\@sm-lg {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .pds-pt\@sm-lg {
    padding-top: 24px !important;
  }
  .pds-pr\@sm-lg {
    padding-right: 24px !important;
  }
  .pds-pb\@sm-lg {
    padding-bottom: 24px !important;
  }
  .pds-pl\@sm-lg {
    padding-left: 24px !important;
  }
  .pds-p\@sm-xl {
    padding: 32px !important;
  }
  .pds-px\@sm-xl {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .pds-py\@sm-xl {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .pds-pt\@sm-xl {
    padding-top: 32px !important;
  }
  .pds-pr\@sm-xl {
    padding-right: 32px !important;
  }
  .pds-pb\@sm-xl {
    padding-bottom: 32px !important;
  }
  .pds-pl\@sm-xl {
    padding-left: 32px !important;
  }
  .pds-p\@sm-xxl {
    padding: 64px !important;
  }
  .pds-px\@sm-xxl {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .pds-py\@sm-xxl {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .pds-pt\@sm-xxl {
    padding-top: 64px !important;
  }
  .pds-pr\@sm-xxl {
    padding-right: 64px !important;
  }
  .pds-pb\@sm-xxl {
    padding-bottom: 64px !important;
  }
  .pds-pl\@sm-xxl {
    padding-left: 64px !important;
  }
}
@media (min-width: 768px) {
  .pds-p\@md-0 {
    padding: 0 !important;
  }
  .pds-px\@md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pds-py\@md-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .pds-pt\@md-0 {
    padding-top: 0 !important;
  }
  .pds-pr\@md-0 {
    padding-right: 0 !important;
  }
  .pds-pb\@md-0 {
    padding-bottom: 0 !important;
  }
  .pds-pl\@md-0 {
    padding-left: 0 !important;
  }
  .pds-p\@md-xs {
    padding: 4px !important;
  }
  .pds-px\@md-xs {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pds-py\@md-xs {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .pds-pt\@md-xs {
    padding-top: 4px !important;
  }
  .pds-pr\@md-xs {
    padding-right: 4px !important;
  }
  .pds-pb\@md-xs {
    padding-bottom: 4px !important;
  }
  .pds-pl\@md-xs {
    padding-left: 4px !important;
  }
  .pds-p\@md-sm {
    padding: 8px !important;
  }
  .pds-px\@md-sm {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pds-py\@md-sm {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .pds-pt\@md-sm {
    padding-top: 8px !important;
  }
  .pds-pr\@md-sm {
    padding-right: 8px !important;
  }
  .pds-pb\@md-sm {
    padding-bottom: 8px !important;
  }
  .pds-pl\@md-sm {
    padding-left: 8px !important;
  }
  .pds-p\@md-md {
    padding: 16px !important;
  }
  .pds-px\@md-md {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .pds-py\@md-md {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .pds-pt\@md-md {
    padding-top: 16px !important;
  }
  .pds-pr\@md-md {
    padding-right: 16px !important;
  }
  .pds-pb\@md-md {
    padding-bottom: 16px !important;
  }
  .pds-pl\@md-md {
    padding-left: 16px !important;
  }
  .pds-p\@md-lg {
    padding: 24px !important;
  }
  .pds-px\@md-lg {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pds-py\@md-lg {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .pds-pt\@md-lg {
    padding-top: 24px !important;
  }
  .pds-pr\@md-lg {
    padding-right: 24px !important;
  }
  .pds-pb\@md-lg {
    padding-bottom: 24px !important;
  }
  .pds-pl\@md-lg {
    padding-left: 24px !important;
  }
  .pds-p\@md-xl {
    padding: 32px !important;
  }
  .pds-px\@md-xl {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .pds-py\@md-xl {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .pds-pt\@md-xl {
    padding-top: 32px !important;
  }
  .pds-pr\@md-xl {
    padding-right: 32px !important;
  }
  .pds-pb\@md-xl {
    padding-bottom: 32px !important;
  }
  .pds-pl\@md-xl {
    padding-left: 32px !important;
  }
  .pds-p\@md-xxl {
    padding: 64px !important;
  }
  .pds-px\@md-xxl {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .pds-py\@md-xxl {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .pds-pt\@md-xxl {
    padding-top: 64px !important;
  }
  .pds-pr\@md-xxl {
    padding-right: 64px !important;
  }
  .pds-pb\@md-xxl {
    padding-bottom: 64px !important;
  }
  .pds-pl\@md-xxl {
    padding-left: 64px !important;
  }
}
@media (min-width: 992px) {
  .pds-p\@lg-0 {
    padding: 0 !important;
  }
  .pds-px\@lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pds-py\@lg-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .pds-pt\@lg-0 {
    padding-top: 0 !important;
  }
  .pds-pr\@lg-0 {
    padding-right: 0 !important;
  }
  .pds-pb\@lg-0 {
    padding-bottom: 0 !important;
  }
  .pds-pl\@lg-0 {
    padding-left: 0 !important;
  }
  .pds-p\@lg-xs {
    padding: 4px !important;
  }
  .pds-px\@lg-xs {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pds-py\@lg-xs {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .pds-pt\@lg-xs {
    padding-top: 4px !important;
  }
  .pds-pr\@lg-xs {
    padding-right: 4px !important;
  }
  .pds-pb\@lg-xs {
    padding-bottom: 4px !important;
  }
  .pds-pl\@lg-xs {
    padding-left: 4px !important;
  }
  .pds-p\@lg-sm {
    padding: 8px !important;
  }
  .pds-px\@lg-sm {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pds-py\@lg-sm {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .pds-pt\@lg-sm {
    padding-top: 8px !important;
  }
  .pds-pr\@lg-sm {
    padding-right: 8px !important;
  }
  .pds-pb\@lg-sm {
    padding-bottom: 8px !important;
  }
  .pds-pl\@lg-sm {
    padding-left: 8px !important;
  }
  .pds-p\@lg-md {
    padding: 16px !important;
  }
  .pds-px\@lg-md {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .pds-py\@lg-md {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .pds-pt\@lg-md {
    padding-top: 16px !important;
  }
  .pds-pr\@lg-md {
    padding-right: 16px !important;
  }
  .pds-pb\@lg-md {
    padding-bottom: 16px !important;
  }
  .pds-pl\@lg-md {
    padding-left: 16px !important;
  }
  .pds-p\@lg-lg {
    padding: 24px !important;
  }
  .pds-px\@lg-lg {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pds-py\@lg-lg {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .pds-pt\@lg-lg {
    padding-top: 24px !important;
  }
  .pds-pr\@lg-lg {
    padding-right: 24px !important;
  }
  .pds-pb\@lg-lg {
    padding-bottom: 24px !important;
  }
  .pds-pl\@lg-lg {
    padding-left: 24px !important;
  }
  .pds-p\@lg-xl {
    padding: 32px !important;
  }
  .pds-px\@lg-xl {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .pds-py\@lg-xl {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .pds-pt\@lg-xl {
    padding-top: 32px !important;
  }
  .pds-pr\@lg-xl {
    padding-right: 32px !important;
  }
  .pds-pb\@lg-xl {
    padding-bottom: 32px !important;
  }
  .pds-pl\@lg-xl {
    padding-left: 32px !important;
  }
  .pds-p\@lg-xxl {
    padding: 64px !important;
  }
  .pds-px\@lg-xxl {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .pds-py\@lg-xxl {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .pds-pt\@lg-xxl {
    padding-top: 64px !important;
  }
  .pds-pr\@lg-xxl {
    padding-right: 64px !important;
  }
  .pds-pb\@lg-xxl {
    padding-bottom: 64px !important;
  }
  .pds-pl\@lg-xxl {
    padding-left: 64px !important;
  }
}
@media (min-width: 1216px) {
  .pds-p\@xl-0 {
    padding: 0 !important;
  }
  .pds-px\@xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pds-py\@xl-0 {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }
  .pds-pt\@xl-0 {
    padding-top: 0 !important;
  }
  .pds-pr\@xl-0 {
    padding-right: 0 !important;
  }
  .pds-pb\@xl-0 {
    padding-bottom: 0 !important;
  }
  .pds-pl\@xl-0 {
    padding-left: 0 !important;
  }
  .pds-p\@xl-xs {
    padding: 4px !important;
  }
  .pds-px\@xl-xs {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .pds-py\@xl-xs {
    padding-bottom: 4px !important;
    padding-top: 4px !important;
  }
  .pds-pt\@xl-xs {
    padding-top: 4px !important;
  }
  .pds-pr\@xl-xs {
    padding-right: 4px !important;
  }
  .pds-pb\@xl-xs {
    padding-bottom: 4px !important;
  }
  .pds-pl\@xl-xs {
    padding-left: 4px !important;
  }
  .pds-p\@xl-sm {
    padding: 8px !important;
  }
  .pds-px\@xl-sm {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .pds-py\@xl-sm {
    padding-bottom: 8px !important;
    padding-top: 8px !important;
  }
  .pds-pt\@xl-sm {
    padding-top: 8px !important;
  }
  .pds-pr\@xl-sm {
    padding-right: 8px !important;
  }
  .pds-pb\@xl-sm {
    padding-bottom: 8px !important;
  }
  .pds-pl\@xl-sm {
    padding-left: 8px !important;
  }
  .pds-p\@xl-md {
    padding: 16px !important;
  }
  .pds-px\@xl-md {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .pds-py\@xl-md {
    padding-bottom: 16px !important;
    padding-top: 16px !important;
  }
  .pds-pt\@xl-md {
    padding-top: 16px !important;
  }
  .pds-pr\@xl-md {
    padding-right: 16px !important;
  }
  .pds-pb\@xl-md {
    padding-bottom: 16px !important;
  }
  .pds-pl\@xl-md {
    padding-left: 16px !important;
  }
  .pds-p\@xl-lg {
    padding: 24px !important;
  }
  .pds-px\@xl-lg {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .pds-py\@xl-lg {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .pds-pt\@xl-lg {
    padding-top: 24px !important;
  }
  .pds-pr\@xl-lg {
    padding-right: 24px !important;
  }
  .pds-pb\@xl-lg {
    padding-bottom: 24px !important;
  }
  .pds-pl\@xl-lg {
    padding-left: 24px !important;
  }
  .pds-p\@xl-xl {
    padding: 32px !important;
  }
  .pds-px\@xl-xl {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .pds-py\@xl-xl {
    padding-bottom: 32px !important;
    padding-top: 32px !important;
  }
  .pds-pt\@xl-xl {
    padding-top: 32px !important;
  }
  .pds-pr\@xl-xl {
    padding-right: 32px !important;
  }
  .pds-pb\@xl-xl {
    padding-bottom: 32px !important;
  }
  .pds-pl\@xl-xl {
    padding-left: 32px !important;
  }
  .pds-p\@xl-xxl {
    padding: 64px !important;
  }
  .pds-px\@xl-xxl {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .pds-py\@xl-xxl {
    padding-bottom: 64px !important;
    padding-top: 64px !important;
  }
  .pds-pt\@xl-xxl {
    padding-top: 64px !important;
  }
  .pds-pr\@xl-xxl {
    padding-right: 64px !important;
  }
  .pds-pb\@xl-xxl {
    padding-bottom: 64px !important;
  }
  .pds-pl\@xl-xxl {
    padding-left: 64px !important;
  }
}
.pds-print-only {
  display: none !important;
}
@media print {
  .pds-print-only {
    display: revert !important;
  }
}

@media print {
  .pds-screen-only {
    display: none !important;
  }
}

.pds-radius-xxl {
  border-radius: 32px !important;
}

.pds-radius-xl {
  border-radius: 24px !important;
}

.pds-radius-lg {
  border-radius: 16px !important;
}

.pds-radius-md {
  border-radius: 8px !important;
}

.pds-radius-sm {
  border-radius: 4px !important;
}

.pds-radius-xs {
  border-radius: 2px !important;
}

.pds-radius-0 {
  border-radius: 0 !important;
}

.pds-h1,
.pds-heading-xl {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.25;
  margin: 0;
  text-wrap: balance;
}

.pds-h2,
.pds-heading-lg {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.25;
  margin: 0;
  text-wrap: balance;
}

.pds-h3,
.pds-heading-md {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  text-wrap: balance;
}

.pds-h4,
.pds-heading-sm {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  text-wrap: balance;
}

.pds-h5,
.pds-heading-xs {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
}

.pds-weight-bold {
  font-weight: 550 !important;
}

.pds-weight-semi {
  font-weight: 500 !important;
}

.pds-weight-normal {
  font-weight: 400 !important;
}

.pds-style-italic {
  font-style: italic !important;
}

.pds-style-normal {
  font-style: normal !important;
}

.pds-text-xl {
  font-size: 20px !important;
}

.pds-text-lg {
  font-size: 17px !important;
}

.pds-text-md {
  font-size: 15px !important;
}

.pds-text-sm {
  font-size: 13px !important;
}

.pds-text-left {
  text-align: left !important;
}

.pds-text-center {
  text-align: center !important;
}

.pds-text-right {
  text-align: right !important;
}

.pds-text-top {
  vertical-align: text-top !important;
}

.pds-text-middle {
  vertical-align: middle !important;
}

.pds-text-bottom {
  vertical-align: text-bottom !important;
}

.pds-lineheight-normal {
  line-height: 1.4 !important;
}

.pds-lineheight-condensed {
  line-height: 1.25 !important;
}

.pds-no-wrap,
.pds-nowrap {
  white-space: nowrap;
}

/* stylelint-disable-line scss/dollar-variable-pattern */
@font-face {
  font-family: Muoto;
  font-weight: 213 723;
  src: url("../../../node_modules/@harvesthq/porchlight/styles/fonts/muoto/MuotoVAR.woff2") format("woff2-variations");
}
@font-face {
  font-family: Muoto;
  font-style: italic;
  font-weight: 213 723;
  src: url("../../../node_modules/@harvesthq/porchlight/styles/fonts/muoto/MuotoVAR-Italic.woff2") format("woff2-variations");
}
* {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
*:focus {
  outline-color: #1d1e1c;
}
*:disabled, *:disabled + label, *[aria-disabled=true], *[aria-disabled=true] + label {
  cursor: not-allowed !important;
  opacity: 0.7;
}

body {
  background: #fff;
  color: #1d1e1c;
  font-family: "Muoto", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  text-wrap: pretty;
}

a {
  color: #2a59c1;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}
.pds-tooltip a {
  color: #86b1f1;
}

b,
strong {
  font-weight: 550;
}

code {
  font-family: monospace;
  font-size: 1em;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.25;
  margin: 0;
  text-wrap: balance;
}

h2 {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.25;
  margin: 0;
  text-wrap: balance;
}

h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  text-wrap: balance;
}

h4 {
  font-size: 17px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  text-wrap: balance;
}

h5,
h6 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
}

hr {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #bbb;
  box-sizing: content-box;
  height: 0;
  margin: 16px 0;
  overflow: visible;
}

img,
svg {
  vertical-align: middle;
}

legend {
  padding: 0;
}

p {
  margin: 0 0 16px;
}

small {
  font-size: 13px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a,
button {
  touch-action: manipulation;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: 1.15;
  margin: 0;
}

button,
[type=button],
[type=reset],
[type=submit] {
  appearance: button;
}

:where(button, [type=button], [type=reset], [type=submit]):not(:disabled) {
  cursor: pointer;
}

:where(button, [type=button], [type=reset], [type=submit])::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[hidden][hidden] {
  display: none !important;
}

svg:not(:root) {
  overflow: hidden;
}

.pds-button, .pds-input::file-selector-button {
  align-items: center;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(29, 30, 28, 0.25);
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
  color: #1d1e1c;
  display: inline-flex;
  font-size: 15px;
  font-weight: 400;
  gap: 4px;
  height: 34px;
  justify-content: center;
  line-height: 34px;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}
.pds-button:hover, .pds-input:hover::file-selector-button {
  border-color: rgba(29, 30, 28, 0.62);
}
.pds-button.pds-button-selected, .pds-button-selected.pds-input::file-selector-button, .pds-button.popover-active, .popover-active.pds-input::file-selector-button {
  background-color: rgba(29, 30, 28, 0.07) !important;
  border-color: #c6c6c6 !important;
  color: rgba(29, 30, 28, 0.7) !important;
  cursor: default;
}
.pds-button:focus, .pds-input:focus::file-selector-button {
  z-index: 200;
}
.pds-button:active, .pds-input:active::file-selector-button {
  background-color: rgba(29, 30, 28, 0.07);
}
.pds-button:is(:disabled, [aria-disabled=true]), .pds-input:is(:disabled, [aria-disabled=true])::file-selector-button {
  background-color: rgba(29, 30, 28, 0.07) !important;
  border-color: #c6c6c6 !important;
  color: rgba(29, 30, 28, 0.62);
}

input.pds-button, input.pds-input::file-selector-button {
  line-height: 1;
}

.pds-button-lg, .pds-input-lg::file-selector-button {
  border-radius: 8px;
  font-size: 17px;
  height: 40px;
  line-height: 40px;
}

.pds-button-sm, .pds-input-sm::file-selector-button {
  border-radius: 4px;
  font-size: 13px;
  height: 29px;
  line-height: 29px;
  padding: 0 8px;
}

.pds-button-xs, .pds-input-xs::file-selector-button {
  border-radius: 4px;
  font-size: 13px;
  height: 25px;
  line-height: 25px;
  padding: 0 8px;
}

.pds-button-primary {
  background-clip: border-box;
  background-color: #188433;
  border-color: transparent !important;
  color: #fff;
  font-weight: 500;
}
.pds-button-primary:hover, .pds-button-primary.pds-button-selected {
  background-color: #11742a;
}
.pds-button-primary:active {
  background-color: #0b6221;
}
.pds-button-primary:is(:disabled, [aria-disabled=true]) {
  background-color: #76bc82 !important;
  border-color: #76bc82 !important;
  color: #fff;
}

.pds-button-charcoal,
.pds-button-running {
  background-clip: border-box;
  background-color: #1d1e1c;
  border-color: transparent !important;
  color: #fff;
  font-weight: 500;
}
.pds-button-charcoal:hover, .pds-button-charcoal.pds-button-selected,
.pds-button-running:hover,
.pds-button-running.pds-button-selected {
  background-color: rgba(29, 30, 28, 0.9);
}
.pds-button-charcoal:active,
.pds-button-running:active {
  background-color: rgba(29, 30, 28, 0.8);
}
.pds-button-charcoal:is(:disabled, [aria-disabled=true]),
.pds-button-running:is(:disabled, [aria-disabled=true]) {
  background-color: #a5a5a4 !important;
  border-color: #a5a5a4 !important;
  color: #fff;
}

.pds-button-danger {
  background-clip: border-box;
  background-color: #d92f2f;
  border-color: transparent !important;
  color: #fff;
  font-weight: 500;
}
.pds-button-danger:hover, .pds-button-danger.pds-button-selected {
  background-color: #be2923;
}
.pds-button-danger:active {
  background-color: #a12318;
}
.pds-button-danger:is(:disabled, [aria-disabled=true]) {
  background-color: #ef8b81 !important;
  border-color: #ef8b81 !important;
  color: #fff;
}

.pds-button-toggle {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
}
.pds-button-toggle .pds-button, .pds-button-toggle .pds-input::file-selector-button {
  background-clip: border-box;
  margin-left: -1px;
}
.pds-button-toggle .pds-button:hover, .pds-button-toggle .pds-input:hover::file-selector-button {
  z-index: 300;
}
.pds-button-toggle .pds-button.pds-button-selected, .pds-button-toggle .pds-button-selected.pds-input::file-selector-button {
  background-color: #ffe7d9 !important;
  border-color: #fa5d00 !important;
  color: #1d1e1c !important;
  opacity: 1 !important;
  z-index: 200;
}
.pds-button-toggle .pds-button:not(:first-child):not(:last-child), .pds-button-toggle .pds-input:not(:first-child):not(:last-child)::file-selector-button {
  border-radius: 0;
}
.pds-button-toggle .pds-button:first-child, .pds-button-toggle .pds-input:first-child::file-selector-button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-left: 0;
}
.pds-button-toggle .pds-button:last-child, .pds-button-toggle .pds-input:last-child::file-selector-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.pds-button-icon {
  padding: 0 !important;
}
.pds-button-icon.pds-button, .pds-button-icon.pds-input::file-selector-button {
  width: 34px;
}
.pds-button-icon.pds-button-lg, .pds-button-icon.pds-input-lg::file-selector-button {
  width: 40px;
}
.pds-button-icon.pds-button-sm, .pds-button-icon.pds-input-sm::file-selector-button {
  width: 29px;
}
.pds-button-icon.pds-button-xs, .pds-button-icon.pds-input-xs::file-selector-button {
  width: 25px;
}

.pds-button-link, .pds-alert-close {
  color: #2a59c1;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  background: transparent;
  border: 0;
  line-height: inherit;
  padding: 0;
}
.pds-tooltip .pds-button-link, .pds-tooltip .pds-alert-close {
  color: #86b1f1;
}

.pds-card, .pds-toast, .pds-alert {
  background: #fff;
  border: 1px solid rgba(29, 30, 28, 0.25);
  border-radius: 0;
  position: relative;
}

.pds-card-secondary {
  background: rgba(29, 30, 28, 0.07);
  border: 1px solid rgba(29, 30, 28, 0.25);
}

.pds-card-notice, .pds-toast-notice, .pds-alert-notice {
  background: #deedfd;
  border: 1px solid #86b1f1;
}

.pds-card-success, .pds-toast-success, .pds-alert-success {
  background: #daefdc;
  border: 1px solid #76bc82;
}

.pds-card-warning, .pds-toast-warning, .pds-alert-warning {
  background: #fff1cd;
  border: 1px solid #ffc33c;
}

.pds-card-danger, .pds-toast-danger, .pds-alert-danger {
  background: #fde2dd;
  border: 1px solid #ef8b81;
}

.pds-card-warm-white, .pds-alert-upsell {
  background: #fff8f1;
  border: 1px solid #ffa26c;
}

.pdsf-card, #errorExplanation {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  position: relative;
}

.pdsf-card-sm {
  border-radius: 4px;
  padding: 8px;
}

.pdsf-card-lg {
  border-radius: 16px;
  padding: 24px;
}

.pdsf-card-xl {
  border-radius: 24px;
  padding: 32px;
}

.pdsf-card-secondary {
  background: rgba(29, 30, 28, 0.07);
  border: 1px solid rgba(29, 30, 28, 0.25);
}

.pdsf-card-notice {
  background: #deedfd;
  border: 1px solid #86b1f1;
}

.pdsf-card-success {
  background: #daefdc;
  border: 1px solid #76bc82;
}

.pdsf-card-warning {
  background: #fff1cd;
  border: 1px solid #ffcf60;
}

.pdsf-card-danger, #errorExplanation {
  background: #fde2dd;
  border: 1px solid #ef8b81;
}

.pdsf-card-warm-white {
  background: #fff8f1;
  border: 1px solid #ffc5a3;
}

.pds-alert {
  padding: 8px;
}
@media (min-width: 544px) {
  .pds-alert {
    align-items: flex-start;
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    padding: 16px;
  }
}
.pds-alert::before {
  display: inline-block;
  height: 20px;
  margin: -1px 8px 0 0;
  pointer-events: none;
  user-select: none;
  vertical-align: text-top;
  width: 20px;
}
@media (min-width: 544px) {
  .pds-alert::before {
    margin-top: 0;
  }
}

.pds-alert-notice::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23376bdd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><circle cx='12' cy='12' r='10'></circle><line x1='12' y1='8' x2='12' y2='12'></line><line x1='12' y1='16' x2='12.01' y2='16'></line></svg>");
}

.pds-alert-success::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23188433' stroke-width='2.25' stroke-linecap='round' stroke-linejoin='round'><polyline points='20 6 9 18 4 12'></polyline></svg>");
}

.pds-alert-warning::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23d99c22' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path><line x1='12' y1='9' x2='12' y2='13'></line><line x1='12' y1='17' x2='12.01' y2='17'></line></svg>");
}

.pds-alert-danger::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23d92f2f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>");
}

.pds-alert-upsell::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23fa5d00' stroke-width='1.75' stroke-linecap='round' stroke-linejoin='round'><circle cx='12' cy='12' r='10'></circle><path d='M15.3334 10.8333L12.0001 7.5L8.66675 10.8333'></path><path d='M15.3334 15.4998L12.0001 12.1665L8.66675 15.4998'></path></svg>");
}

.pds-alert-close {
  display: block;
  margin: 4px 0 0 auto;
}
@media (min-width: 544px) {
  .pds-alert-close {
    margin: 0 0 0 24px;
  }
}

.pds-avatar {
  border-radius: 100%;
}

.pds-avatar-muted {
  opacity: 0.7;
}

.pds-badge, .pds-tab-count {
  background: transparent;
  border: 1px solid rgba(29, 30, 28, 0.3);
  border-radius: 32px;
  color: rgba(29, 30, 28, 0.7);
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  padding: 4px 8px;
  vertical-align: middle;
  white-space: nowrap;
}

.pds-badge-charcoal {
  background: #1d1e1c;
  border-color: transparent;
  color: #fff;
}

.pds-badge-charcoal-inverse, .pds-tab-count {
  background: rgba(29, 30, 28, 0.07);
  border-color: transparent;
  color: rgba(29, 30, 28, 0.8);
}

.pds-badge-green {
  background: #188433;
  border-color: transparent;
  color: #fff;
}

.pds-badge-green-inverse {
  background: #daefdc;
  border-color: transparent;
  color: #11742a;
}

.pds-badge-blue {
  background: #376bdd;
  border-color: transparent;
  color: #fff;
}

.pds-badge-blue-inverse {
  background: #deedfd;
  border-color: transparent;
  color: #2a59c1;
}

.pds-badge-orange {
  background: #fa5d00;
  border-color: transparent;
  color: #fff;
}

.pds-badge-orange-inverse {
  background: #ffe7d9;
  border-color: transparent;
  color: #df4b00;
}

.pds-badge-red {
  background: #d92f2f;
  border-color: transparent;
  color: #fff;
}

.pds-badge-red-inverse {
  background: #fde2dd;
  border-color: transparent;
  color: #be2923;
}

.pds-chosen-container {
  display: inline-block;
  max-width: 100%;
  position: relative;
  vertical-align: middle;
}

.pds-chosen-backdrop {
  background: rgba(29, 30, 28, 0.62);
  inset: 0;
  overflow: auto;
  overscroll-behavior: contain;
  position: fixed;
  text-align: left;
  transition: opacity 0s ease;
  white-space: normal;
  z-index: 600;
}
@media (min-width: 544px) {
  .pds-chosen-backdrop {
    background: transparent;
    overflow: revert;
    overscroll-behavior: revert;
    position: relative;
  }
}

.pds-chosen-selection,
.pds-chosen-selection div {
  overflow: hidden;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pds-chosen-with-label .pds-chosen-selection {
  display: flex;
  flex-direction: column;
  margin: 8px 0;
}

.pds-chosen-button {
  max-width: 100%;
}
.pds-chosen-button svg {
  stroke: rgba(29, 30, 28, 0.7);
}

.pds-chosen-with-label .pds-chosen-button {
  height: revert;
}

.pds-chosen-dropdown {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(29, 30, 28, 0.15);
  border-radius: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  left: 8px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 400;
}
@media (min-width: 544px) {
  .pds-chosen-dropdown {
    bottom: revert;
    left: revert;
    padding: 0;
    position: absolute;
    right: revert;
    top: calc(100% + 4px);
  }
  .pds-chosen-dropdown::after {
    border-bottom: 8px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: "";
    filter: drop-shadow(0 -1px 0 rgba(29, 30, 28, 0.3));
    left: 8px;
    pointer-events: none;
    position: absolute;
    top: -8px;
  }
}

.pds-chosen-search {
  padding: 8px 8px 0;
}

.pds-chosen-input {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><path opacity=".5" d="M16 14.4l-4.2-4.1c.8-1.1 1.2-2.4 1.2-3.8 0-3.6-2.9-6.5-6.5-6.5s-6.5 2.9-6.5 6.5 2.9 6.5 6.5 6.5c1.4 0 2.6-.4 3.7-1.1l4.2 4.2 1.6-1.7zm-14.1-7.9c0-2.5 2.1-4.6 4.6-4.6s4.6 2.1 4.6 4.6-2.1 4.6-4.6 4.6-4.6-2.1-4.6-4.6z"/></svg>');
  background-position: 8px 50%;
  background-repeat: no-repeat;
  padding-left: 32px !important;
}

.pds-chosen-results {
  margin: 8px 0;
  max-height: 50vh;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding: 0;
  position: relative;
}
@media (min-width: 544px) {
  .pds-chosen-results {
    overscroll-behavior: revert;
  }
}
@media (min-height: 650px) {
  .pds-chosen-results {
    max-height: 360px;
  }
}

.pds-chosen-result,
.pds-chosen-result-group {
  color: #1d1e1c;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 8px 32px 8px 16px;
  text-overflow: ellipsis;
  -webkit-touch-callout: none;
  word-wrap: break-word;
}
.pds-chosen-result em,
.pds-chosen-result-group em {
  font-style: normal;
  text-decoration: underline;
  text-underline-offset: 1px;
}

.pds-chosen-result {
  cursor: pointer;
}

.pds-chosen-highlighted {
  background: #1d1e1c;
  color: #fff;
}

.pds-chosen-no-results {
  background: transparent;
  color: rgba(29, 30, 28, 0.62);
  font-style: italic;
  margin: 8px 0;
  padding: 8px 32px 8px 16px;
}

.pds-chosen-and-more {
  color: rgba(29, 30, 28, 0.62);
  font-size: 13px;
  font-style: italic;
  margin: 4px 0;
  text-align: center;
}

.pds-chosen-result-group {
  font-weight: 550;
  letter-spacing: 0;
}

.pds-chosen-group-option {
  padding-left: 32px;
}

.pds-chosen-clear-selection-container {
  border-top: 1px solid rgba(29, 30, 28, 0.25);
  display: flex;
  justify-content: space-between;
  padding: 4px;
}

.pds-chosen-clear-selection {
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  color: inherit;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  margin: 4px 0 4px auto;
  padding: 4px 8px;
  text-decoration: none;
  transition: none;
  width: auto;
}
.pds-chosen-clear-selection > svg {
  margin-right: 4px;
}
.pds-chosen-clear-selection:hover {
  background-color: inherit;
  color: inherit;
  text-decoration: underline;
  text-underline-offset: 1px;
}

.pds-chosen-select {
  width: 100%;
}
.pds-chosen-select .pds-chosen-button {
  border-radius: 8px;
  justify-content: space-between;
  line-height: 1.25;
  padding: 0 8px;
  text-align: left;
  width: 100%;
}
.pds-chosen-select .pds-chosen-blank:not([disabled]) {
  color: rgba(29, 30, 28, 0.62);
}

@media (min-width: 544px) {
  .pds-chosen-select.pds-chosen-open .pds-chosen-button {
    background-color: rgba(29, 30, 28, 0.07);
    border-color: rgba(29, 30, 28, 0.25);
    box-shadow: none;
    color: rgba(29, 30, 28, 0.62);
    cursor: default;
  }
  .pds-chosen-select .pds-chosen-dropdown {
    max-width: 100%;
    width: 100%;
  }
}
@media (min-width: 544px) {
  .pds-chosen-menu .pds-chosen-button {
    max-width: 280px;
  }
  .pds-chosen-menu .pds-chosen-dropdown {
    width: 280px;
  }
  .pds-chosen-menu .pds-chosen-left {
    left: 0;
  }
  .pds-chosen-menu .pds-chosen-right {
    right: 0;
  }
  .pds-chosen-menu .pds-chosen-right::after {
    left: auto;
    right: 8px;
  }
}
[class*=pds-container] {
  margin-inline: auto;
  padding-inline: 16px;
  width: 100%;
}
@media print {
  [class*=pds-container] {
    max-width: 100% !important;
  }
}

.pds-container-sm {
  max-width: 544px;
}

.pds-container-md {
  max-width: 768px;
}

.pds-container-lg {
  max-width: 992px;
}

.pds-container {
  max-width: 1216px;
}

.pds-dialog-backdrop {
  background: rgba(29, 30, 28, 0.62);
  inset: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  text-align: left;
  transition: opacity 0s ease;
  visibility: hidden;
  white-space: normal;
  z-index: 600;
}

.pds-dialog {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(29, 30, 28, 0.15);
  border-radius: 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  line-height: 1.4;
  margin: 4px;
  padding: 16px;
}
@media (min-width: 768px) {
  .pds-dialog {
    left: 50%;
    margin-left: -300px;
    padding: 24px;
    position: absolute;
    top: 10%;
    width: 600px;
  }
}

.pdsf-dialog {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(29, 30, 28, 0.15);
  border-radius: 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  line-height: 1.4;
  margin: 4px;
  max-height: calc(100dvh - 8px);
  max-width: calc(100dvw - 8px);
  padding: 16px;
  position: fixed;
  width: 100dvw;
}
@media (min-width: 768px) {
  .pdsf-dialog {
    left: 50%;
    margin-left: -300px;
    max-height: 80dvh;
    padding: 24px;
    top: 10dvh;
    width: 600px;
  }
}
.pdsf-dialog::backdrop {
  background: rgba(29, 30, 28, 0.62);
  opacity: 0;
  transition: opacity 0s ease;
  visibility: hidden;
}

.pdsf-dialog-close {
  align-items: center;
  background: transparent;
  border-color: transparent;
  color: rgba(29, 30, 28, 0.7);
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 32px;
}

.pds-dialog-open {
  opacity: 1;
  transition-duration: 0.2s;
  visibility: visible;
}
.pds-dialog-open::backdrop {
  opacity: 1;
  transition-duration: 0.2s;
  visibility: visible;
}

@media (min-width: 768px) {
  .pds-dialog-sm {
    margin-left: -200px;
    width: 400px;
  }
}

@media (min-width: 768px) {
  .pds-dialog-md {
    margin-left: -255px;
    width: 510px;
  }
}

.pds-dialog-title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  background: rgba(29, 30, 28, 0.07);
  border-bottom: 1px solid rgba(29, 30, 28, 0.25);
  margin: -16px -16px 16px;
  padding: 8px 0;
  text-align: center;
  user-select: none;
}
@media (min-width: 768px) {
  .pds-dialog-title {
    margin: -24px -24px 24px;
  }
}

.pds-dialog-close {
  background: #1d1e1c;
  border: 2px solid #fff;
  border-radius: 100%;
  color: #fff;
  line-height: 0;
  padding: 8px;
  position: absolute;
  right: 4px;
  top: 4px;
}
@media (min-width: 768px) {
  .pds-dialog-close {
    right: -10px;
    top: -10px;
  }
}

@media print {
  .pds-dialog-backdrop,
  .pdsf-dialog::backdrop {
    display: none !important;
  }
}
.pds-draggable-select {
  flex: 1;
  flex-direction: row;
}

.pds-select-type-wrapper {
  background-color: #fff;
  border: 1px solid #bbb;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 8px;
}

.pds-select-type-name {
  margin-bottom: 2px;
}

.pds-select-type-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  list-style: none;
}

.pds-select-item {
  border-radius: 8px;
  opacity: 0.999;
  padding-bottom: 4px;
}

.pds-select-content {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
}
.pds-select-content > svg {
  color: #8d8e8d;
  flex: none;
}

.pds-select-item.pds-dragging {
  cursor: grabbing;
}
.pds-select-item.pds-dragging > .pds-select-content {
  background-color: #eee;
  border: 1px dashed #bbb;
  border-radius: 4px;
}
.pds-select-item.pds-dragging > .pds-select-content > p,
.pds-select-item.pds-dragging > .pds-select-content svg {
  opacity: 0;
}

.pds-invisible-select-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.pds-dragselect-empty-state {
  color: #737472;
}

.pds-empty {
  background: rgba(29, 30, 28, 0.07);
  border-radius: 16px;
  color: rgba(29, 30, 28, 0.7);
  font-size: 17px;
  padding: 32px;
  text-align: center;
  text-wrap: balance;
}
@media (min-width: 768px) {
  .pds-empty {
    padding: 64px;
  }
}
.pds-empty img {
  max-width: 100%;
  object-fit: contain;
}

[class*=pds-flex-list] {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
@media print {
  [class*=pds-flex-list]::after {
    content: "";
    height: 0;
    visibility: hidden;
    width: 0;
  }
}

.pds-flex-list\@xs-stretch {
  align-items: stretch;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 544px) {
  .pds-flex-list\@xs-stretch {
    align-items: center;
    flex-direction: row;
    width: auto;
  }
}

.pds-label {
  align-items: center;
  display: flex;
  font-weight: 500;
  margin-bottom: 4px;
}

@media (min-width: 768px) {
  [class*=pds-column] > .pds-label {
    margin: 0;
  }
  .pds-label-sm {
    min-height: 29px;
  }
  .pds-label-md {
    min-height: 34px;
  }
  .pds-label-lg {
    min-height: 40px;
  }
  .pds-label-xl {
    min-height: 44px;
  }
}
.pds-input {
  appearance: none;
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(29, 30, 28, 0.3);
  border-radius: 8px;
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.04);
  color: #1d1e1c;
  display: inline-block;
  font-size: 15px;
  height: 34px;
  line-height: 30px;
  max-width: 100%;
  padding: 0 8px;
  vertical-align: middle;
  width: 100%;
}
.pds-input::placeholder {
  color: rgba(29, 30, 28, 0.62);
}
.pds-input::file-selector-button {
  border-radius: 0;
  margin-left: calc(-8px - 1px);
  margin-right: 16px;
  margin-top: -1px;
  pointer-events: none;
  vertical-align: baseline;
}
.pds-input[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.pds-input:hover {
  border-color: rgba(29, 30, 28, 0.62);
}
.pds-input:focus {
  border-color: rgba(29, 30, 28, 0.62);
  z-index: 200;
}
.pds-input[readonly] {
  background: rgba(29, 30, 28, 0.07);
  border-color: rgba(29, 30, 28, 0.25);
  color: rgba(29, 30, 28, 0.7);
}
.pds-input:disabled {
  background-color: rgba(29, 30, 28, 0.07);
  border-color: rgba(29, 30, 28, 0.25);
  color: rgba(29, 30, 28, 0.62);
  -webkit-text-fill-color: rgba(29, 30, 28, 0.62);
}

.pds-input-xs {
  border-radius: 4px;
  font-size: 13px;
  height: 25px;
  line-height: 21px;
}
.pds-input-xs::file-selector-button {
  margin-right: 8px;
}

.pds-input-sm {
  border-radius: 4px;
  font-size: 13px;
  height: 29px;
  line-height: 25px;
}
.pds-input-sm::file-selector-button {
  margin-right: 8px;
}

.pds-input-lg {
  border-radius: 8px;
  font-size: 18px;
  height: 40px;
  line-height: 36px;
}
select.pds-input {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="%23666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline points="5 9 12 17 19 9" /></svg>');
  background-position: calc(100% - 8px) 50%;
  background-repeat: no-repeat;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  padding-right: 32px;
  width: auto;
}

textarea.pds-input {
  height: auto;
  line-height: 1.4;
  overflow: auto;
  padding-bottom: calc(8px - 2px);
  padding-top: calc(8px - 2px);
  resize: vertical;
}

.pds-input-nested-label {
  position: relative;
}
.pds-input-nested-label .pds-label {
  color: rgba(29, 30, 28, 0.7);
  font-size: 13px;
  font-weight: 400;
  left: 8px;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 4px;
}
.pds-input-nested-label .pds-input {
  height: 44px;
  padding-top: 16px;
}

:is(.pds-checkbox, .pds-radio) {
  display: flex;
  gap: 8px;
}
:is(.pds-checkbox, .pds-radio) :is([type=checkbox], [type=radio]) {
  appearance: none;
  background-color: #fff;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 75%;
  border: 1px solid rgba(29, 30, 28, 0.3);
  box-sizing: content-box;
  cursor: pointer;
  flex: 0 0 16px;
  height: 16px;
  transform: translateY(2px);
  width: 16px;
}
:is(.pds-checkbox, .pds-radio) :is([type=checkbox], [type=radio]):hover:not(:checked):not(:disabled, [aria-disabled=true]) {
  border-color: rgba(29, 30, 28, 0.62);
}
.pds-table :is(.pds-checkbox, .pds-radio) :is([type=checkbox], [type=radio]) {
  transform: translateY(0);
}
:is(.pds-checkbox, .pds-radio) [type=checkbox] {
  border-radius: 4px;
}
:is(.pds-checkbox, .pds-radio) [type=checkbox]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 18 18" fill="none" stroke="%23fff" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"><path d="M15.6667 5L6.5 14.1667L2.33333 10" /></svg>');
}
:is(.pds-checkbox, .pds-radio) [type=radio] {
  border-radius: 16px;
}
:is(.pds-checkbox, .pds-radio) [type=radio]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="%23fff"><circle cx="9" cy="9" r="5" /></svg>');
}
:is(.pds-checkbox, .pds-radio) :is([type=checkbox], [type=radio]):is([disabled], [aria-disabled=true]) {
  background-color: #bbb !important;
  opacity: 0.5;
}
:is(.pds-checkbox, .pds-radio) :is([type=checkbox], [type=radio]):is([disabled], [aria-disabled=true]) + label {
  color: rgba(29, 30, 28, 0.62);
}
:is(.pds-checkbox, .pds-radio) :checked {
  background-color: #fa5d00;
}
:is(.pds-checkbox, .pds-radio) :checked:hover {
  background-color: #df4b00;
}
:is(.pds-checkbox, .pds-radio) :focus {
  outline: auto 5px #1d1e1c;
  outline-offset: 1px;
}
:is(.pds-checkbox, .pds-radio).pds-text-sm label {
  transform: translateY(2px);
}
:is(.pds-checkbox, .pds-radio).pds-text-lg label {
  transform: translateY(-1px);
}
:is(.pds-checkbox, .pds-radio) .pds-input {
  margin: -8px 4px;
  vertical-align: baseline;
}

.pds-choices {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  grid-template-rows: 1fr;
  justify-content: center;
}

.pds-choice label {
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  font-size: 15px;
  height: 100%;
  min-height: 34px;
  padding: 4px;
  place-items: center;
  position: relative;
  text-align: center;
}
.pds-choice label:hover {
  border-color: rgba(29, 30, 28, 0.62);
}
.pds-choice input {
  clip-path: polygon(0 0);
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.pds-choice input:checked + label {
  background: #ffe7d9;
  border-color: #fa5d00;
}
.pds-choice input:disabled + label {
  background: #eee;
  border-color: #c6c6c6;
  color: rgba(29, 30, 28, 0.62);
}
.pds-choice input:focus + label {
  border-color: #fa5d00;
  outline: auto 5px #1d1e1c;
  outline-offset: 2px;
}

.pds-input-invalid, .field_with_errors :is(input, textarea),
.braintree-hosted-fields-invalid,
.file-input.pds-input-invalid {
  background: #fde2dd;
  border-color: #d92f2f;
}

.pds-field-description,
.pds-invalid-description {
  color: rgba(29, 30, 28, 0.7);
  display: block;
  font-size: 13px;
  margin-top: 4px;
}

.pds-invalid-description {
  color: #d92f2f;
}

.pds-row {
  display: flex;
  flex-wrap: wrap;
  margin-inline: -16px;
}

[class*=pds-column] {
  flex-basis: 0;
  flex-grow: 1;
  min-height: 1px;
  padding-inline: 16px;
  position: relative;
}

.pds-column-1 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-1 {
  margin-left: 0;
}

.pds-column-2 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-2 {
  margin-left: 0;
}

.pds-column-3 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-3 {
  margin-left: 0;
}

.pds-column-4 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-4 {
  margin-left: 0;
}

.pds-column-5 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-5 {
  margin-left: 0;
}

.pds-column-6 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-6 {
  margin-left: 0;
}

.pds-column-7 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-7 {
  margin-left: 0;
}

.pds-column-8 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-8 {
  margin-left: 0;
}

.pds-column-9 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-9 {
  margin-left: 0;
}

.pds-column-10 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-10 {
  margin-left: 0;
}

.pds-column-11 {
  flex: 0 0 100%;
  max-width: 100%;
}

.pds-offset-11 {
  margin-left: 0;
}

.pds-column-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media screen and (min-width: 768px), print {
  .pds-column-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .pds-offset-1 {
    margin-left: 8.3333333333%;
  }
  .pds-column-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .pds-offset-2 {
    margin-left: 16.6666666667%;
  }
  .pds-column-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .pds-offset-3 {
    margin-left: 25%;
  }
  .pds-column-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .pds-offset-4 {
    margin-left: 33.3333333333%;
  }
  .pds-column-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .pds-offset-5 {
    margin-left: 41.6666666667%;
  }
  .pds-column-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .pds-offset-6 {
    margin-left: 50%;
  }
  .pds-column-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .pds-offset-7 {
    margin-left: 58.3333333333%;
  }
  .pds-column-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .pds-offset-8 {
    margin-left: 66.6666666667%;
  }
  .pds-column-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .pds-offset-9 {
    margin-left: 75%;
  }
  .pds-column-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .pds-offset-10 {
    margin-left: 83.3333333333%;
  }
  .pds-column-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .pds-offset-11 {
    margin-left: 91.6666666667%;
  }
  .pds-column-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.pds-menu-container {
  display: inline-block;
  position: relative;
}

.pds-menu-backdrop {
  background: rgba(29, 30, 28, 0.62);
  inset: 0;
  overflow: auto;
  position: fixed;
  text-align: left;
  white-space: normal;
  z-index: 600;
}
@media (min-width: 768px) {
  .pds-menu-backdrop {
    background: transparent;
    overflow: revert;
    position: relative;
    top: 4px;
  }
}

.pds-menu {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(29, 30, 28, 0.15);
  border-radius: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  left: 8px;
  padding: 8px 0;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 400;
}
@media (min-width: 768px) {
  .pds-menu {
    bottom: revert;
    left: revert;
    position: absolute;
    right: revert;
    top: revert;
  }
  .pds-menu::after {
    border-bottom: 8px solid #fff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    content: "";
    filter: drop-shadow(0 -1px 0 rgba(29, 30, 28, 0.3));
    left: 8px;
    pointer-events: none;
    position: absolute;
    top: -8px;
  }
}

@media (min-width: 768px) {
  .pds-menu-left {
    left: 0;
  }
  .pds-menu-right {
    right: 0;
  }
  .pds-menu-right::after {
    left: auto;
    right: 8px;
  }
}
.pds-menu-item {
  background: transparent;
  border: 0;
  color: #1d1e1c;
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.25;
  padding: 8px 24px 8px 16px;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}
.pds-menu-item.pds-button {
  border-radius: 0;
  box-shadow: none;
}
.pds-menu-item:is(:active, :focus) {
  background: #1d1e1c;
  color: #fff;
}
.pds-menu-item.pds-menu-item-danger:focus {
  background: #d92f2f;
  color: #fff;
}
.pds-menu-item[disabled], .pds-menu-item[aria-disabled=true] {
  color: rgba(29, 30, 28, 0.7);
}
.pds-menu-item[disabled]:is(:active, :focus), .pds-menu-item[aria-disabled=true]:is(:active, :focus) {
  background: transparent;
  color: rgba(29, 30, 28, 0.7);
}

.pds-menu-separator {
  border-top: 1px solid #c6c6c6;
  margin-top: 8px;
  padding-top: 8px;
}

.pds-meter {
  height: 16px;
  position: relative;
}
@media (min-width: 544px) {
  .pds-meter {
    width: 128px;
  }
}
.pds-stat-inline .pds-meter {
  margin: 2px 0 1px;
}

.pds-meter-track {
  background: #eee;
  border-radius: 4px;
  display: flex;
  height: 100%;
  overflow: hidden;
}
.pds-meter-track::after {
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px rgba(29, 30, 28, 0.25);
  content: "";
  inset: 0;
  position: absolute;
}
.pds-meter-danger .pds-meter-track {
  background: #376bdd;
  justify-content: flex-end;
}
.pds-meter-marks .pds-meter-track::after {
  background-image: repeating-linear-gradient(to right, transparent, transparent 20%, rgba(29, 30, 28, 0.25) 20%, rgba(29, 30, 28, 0.25) calc(20% + 1px));
}

.pds-meter-fill {
  color: transparent;
  height: 100%;
  position: relative;
  user-select: none;
}
.pds-meter-danger .pds-meter-fill:nth-child(1) {
  background: #d92f2f !important;
  box-shadow: -1px 0 0 #a12318;
}
.pds-meter-danger .pds-meter-fill:nth-child(2) {
  background: #ef8b81 !important;
}

.pds-meter-budget.pds-meter-danger .pds-meter-track {
  overflow: visible;
}
.pds-meter-budget.pds-meter-danger .pds-meter-fill {
  border-radius: 0 4px 4px 0;
}
.pds-meter-budget.pds-meter-danger .pds-meter-fill::before {
  background: #1d1e1c;
  content: "";
  height: calc(100% + 4px);
  left: -2px;
  position: absolute;
  top: -2px;
  user-select: none;
  width: 2px;
}

.pds-meter-capacity.pds-meter-danger .pds-meter-track::before {
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 4L0 0H4L8 4L4 8H0L4 4Z' fill='white'/%3E%3C/svg%3E");
  background-position: 0 0;
  background-repeat: repeat-y;
  background-size: 8px 8px;
  content: "";
  height: 200%;
  position: absolute;
  right: 8px;
  top: -2px;
  width: 8px;
  z-index: 1;
}

.pds-meter-lg {
  height: 32px;
}
@media (min-width: 544px) {
  .pds-meter-lg {
    width: 256px;
  }
}

.pds-chosen-container.pds-chosen-open .pds-multi-select-choices {
  border-color: #1d1e1c;
  outline: 2px solid #1d1e1c;
  outline-offset: -2px;
  z-index: 2;
}

.chosen-container.chosen-with-drop .pds-multi-select-choices {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none !important;
}

.chosen-container.chosen-with-drop .pds-multi-select-choices:hover {
  border-color: #1d1e1c;
}

.pds-multi-select-choices {
  background: #fff !important;
  border-radius: 8px;
  color: rgba(29, 30, 28, 0.62);
  cursor: text !important;
  flex-wrap: wrap;
  height: auto;
  justify-content: start;
  line-height: 1.25;
  margin: 0;
  min-height: 34px;
  padding: 2px 8px 4px;
  position: relative;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
  width: 100%;
}

.pds-multi-select-choices.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.pds-multi-select-choices.pds-button-sm {
  min-height: 29px;
}

.pds-multi-select-choices:hover {
  border-color: rgba(29, 30, 28, 0.7);
}

.pds-multi-select-choices li {
  float: left;
  list-style: none;
}

.pds-multi-select-choices li.search-field {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  width: inherit;
}

.pds-multi-select-nested-label {
  position: relative;
}
.pds-multi-select-nested-label .pds-label {
  color: rgba(29, 30, 28, 0.7);
  font-size: 13px;
  font-weight: 400;
  left: 8px;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 4px;
}
.pds-multi-select-nested-label .pds-input {
  height: 44px;
  padding-top: 16px;
}

.pds-multi-select-nested-label li.search-field {
  margin-top: 16px !important;
}

.pds-multi-select-choices li.search-field input {
  background: transparent !important;
  border: 0 !important;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #1d1e1c;
  font-family: "Muoto", -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
  font-size: 100%;
  line-height: normal;
  margin: 1px 0;
  outline: 0;
  padding: 0;
  width: 100%;
}

.pds-multi-select-nested-label-container {
  padding-top: 20px;
}

.pds-multi-select-choices li.search-choice {
  background: #ffe7d9 !important;
  background-clip: padding-box;
  background-repeat: repeat-x;
  background-size: 100% 19px;
  border: 1px solid #aaa;
  border-color: #fa5d00 !important;
  border-radius: 32px !important;
  -webkit-box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: none !important;
  color: #333;
  cursor: default;
  font-size: 13px !important;
  line-height: 23px !important;
  margin: 2px 4px 0 0 !important;
  max-width: 100%;
  padding: 0 24px 0 8px !important;
  position: relative;
  text-overflow: clip;
}

.pds-multi-select-choices li.search-choice span {
  word-wrap: break-word;
}

.pds-multi-select-choices li.search-choice .search-choice-close {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="%231d1e1c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18" /><line x1="6" y1="6" x2="18" y2="18" /></svg>') !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  cursor: pointer;
  display: block;
  font-size: 1px;
  height: 15px !important;
  margin-top: -8px;
  opacity: 0.7;
  position: absolute;
  right: 4px !important;
  top: 50% !important;
  width: 15px !important;
}

.pds-multi-select-choices li.search-choice .search-choice-close:hover {
  opacity: 1;
}

.pds-multi-select-choices li.search-choice-disabled {
  background-color: #e4e4e4;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), to(#eee));
  background-image: linear-gradient(#f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
  border: 1px solid #ccc;
  color: #666;
  padding-right: 5px;
}

.pds-multi-select-choices li.search-choice-focus {
  background: #d4d4d4;
}

.pds-multi-select-results {
  margin: 8px 0;
  max-height: 50vh;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.pds-multi-select-results:empty {
  margin: 0;
}

.pds-multi-select-results li {
  border-radius: 0;
  color: #1d1e1c;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1;
  overflow: hidden;
  padding: 8px 32px 8px 16px;
  text-overflow: ellipsis;
  text-shadow: none;
}

.pds-multi-select-results li.highlighted-result,
.pds-multi-select-results li.highlighted-result:active {
  background: #1d1e1c !important;
  color: #fff !important;
}

.pds-multi-select-results li.selected-result {
  display: none;
}

.pds-multi-select-results li.group-result {
  font-weight: 550;
}

.pds-multi-select-results li.group-option {
  padding-left: 32px;
}

.pds-multi-select-results li.no-results {
  background: transparent;
  color: rgba(29, 30, 28, 0.62);
  font-style: italic;
}

.pds-multi-select-choices.pds-button-sm .pds-multi-select-search-input,
.pds-multi-select-choices.pds-button-sm .pds-multi-select-search-input:focus {
  height: 16px;
}

.pds-multi-select-search-input,
.pds-multi-select-search-input:focus {
  border: transparent;
  color: #1d1e1c;
  flex-grow: 1;
  height: 24px;
  margin-top: 2px;
  min-width: 25%;
  outline: none;
}

.pds-multi-select-dropdown {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(29, 30, 28, 0.15);
  border-radius: 8px;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
  z-index: 400;
}
@media (min-width: 544px) {
  .pds-multi-select-dropdown {
    bottom: revert;
    padding: 0;
    position: absolute;
  }
}

.hidden {
  display: none;
}

.multi-select-menu-backdrop {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
}

.pds-pagination ul {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: center;
}
.pds-pagination li > * {
  align-items: center;
  border-radius: 8px;
  cursor: default;
  display: flex;
  font-size: 15px;
  height: 32px;
  justify-content: center;
  min-width: 32px;
  padding: 0 16px;
}
.pds-pagination a {
  cursor: pointer;
  text-decoration: none;
  transition: 0.05s;
}
.pds-pagination a:hover {
  background: #deedfd;
}
.pds-pagination a:active,
.pds-pagination em {
  background: #376bdd;
  color: #fff;
  font-style: normal;
}
.pds-pagination span {
  color: rgba(29, 30, 28, 0.62);
}
.pds-pagination [aria-disabled=true] {
  cursor: not-allowed !important;
  opacity: 0.7;
}

.pds-popover-backdrop {
  background: rgba(29, 30, 28, 0.62);
  inset: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  text-align: left;
  transition: opacity 0s ease;
  visibility: hidden;
  white-space: normal;
  z-index: 600;
}
@media (min-width: 768px) {
  .pds-popover-backdrop {
    background: transparent;
    position: revert;
  }
}

.pds-popover-open {
  opacity: 1;
  transition: none;
  visibility: visible;
}

.pds-popover {
  background: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(29, 30, 28, 0.15);
  border-radius: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  left: 8px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 400;
}
@media (min-width: 768px) {
  .pds-popover {
    bottom: revert;
    left: revert;
    position: absolute;
    right: revert;
    top: revert;
  }
  .pds-popover::after {
    content: "";
    pointer-events: none;
    position: absolute;
  }
}

.pds-popover-nw::after,
.pds-popover-n::after,
.pds-popover-ne::after,
.pds-popover-sw::after,
.pds-popover-s::after,
.pds-popover-se::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.pds-popover-nw::after,
.pds-popover-n::after,
.pds-popover-ne::after {
  border-top: 8px solid #fff;
  filter: drop-shadow(0 1px 0 rgba(29, 30, 28, 0.3));
  bottom: -8px;
}

.pds-popover-sw::after,
.pds-popover-s::after,
.pds-popover-se::after {
  border-bottom: 8px solid #fff;
  filter: drop-shadow(0 -1px 0 rgba(29, 30, 28, 0.3));
  top: -8px;
}

.pds-popover-ne::after,
.pds-popover-se::after {
  left: 8px;
}

.pds-popover-n::after,
.pds-popover-s::after {
  left: 50%;
  margin-left: -8px;
}

.pds-popover-nw::after,
.pds-popover-sw::after {
  right: 8px;
}

.pds-popover-e::after,
.pds-popover-w::after {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
}

.pds-popover-e::after {
  border-right: 8px solid #fff;
  filter: drop-shadow(-1px 0 0 #bbb);
  left: -8px;
  margin-top: -8px;
  top: 50%;
}

.pds-popover-w::after {
  border-left: 8px solid #fff;
  filter: drop-shadow(1px 0 0 #bbb);
  right: -8px;
  margin-top: -8px;
  top: 50%;
}

.pds-skeleton {
  animation: skeleton-loading 0.75s linear infinite alternate;
  display: inline-block;
}

.pds-skeleton-x-sm {
  width: 48px;
}

.pds-skeleton-x-md {
  width: 96px;
}

.pds-skeleton-x-lg {
  width: 192px;
}

.pds-skeleton-y-sm {
  border-radius: 2px;
  height: 8px;
}

.pds-skeleton-y-md {
  border-radius: 4px;
  height: 16px;
}

.pds-skeleton-y-lg {
  border-radius: 8px;
  height: 24px;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(29, 30, 28, 0.07);
  }
  100% {
    background-color: rgba(29, 30, 28, 0.4);
  }
}
.pds-slats-wrapper {
  display: grid;
  grid-template-columns: 16px auto 16px;
  grid-template-rows: auto;
  margin-inline: -16px;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .pds-slats-wrapper {
    grid-template-columns: 0 auto 0;
    margin-inline: 0;
    overflow-x: revert;
  }
}
@media print {
  .pds-slats-wrapper {
    margin-inline: 0;
    overflow-x: revert;
  }
}
.pds-slats-wrapper::before, .pds-slats-wrapper::after {
  content: "";
}
.pds-slats-wrapper::-webkit-scrollbar {
  display: none;
}
.pds-slats-wrapper .pds-slat {
  min-width: 768px;
}
@media (min-width: 544px) {
  .pds-slats-wrapper .pds-slat {
    min-width: 100%;
  }
}

.pds-slat {
  align-items: center;
  background-clip: padding-box;
  border-top: 1px solid rgba(29, 30, 28, 0.25);
  display: flex;
  gap: 16px;
  padding: 8px;
}
.pds-slat:last-child {
  border-bottom: 1px solid rgba(29, 30, 28, 0.25);
}

.pds-slat-header {
  background-color: rgba(29, 30, 28, 0.07);
  color: rgba(29, 30, 28, 0.7);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.25;
}

.pds-stat-block-data {
  font-size: 25px;
  font-weight: 500;
  line-height: 1.25;
  margin: 0;
  text-wrap: balance;
  font-weight: 550;
}

.pds-stat-inline {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: space-between;
}

.pds-stat-inline-data {
  font-size: 15px;
  font-weight: 550;
}

.pds-stat-key {
  border-radius: 4px;
  display: inline-block;
  height: 1em;
  position: relative;
  top: 0.15em;
  width: 1em;
}

.pds-table-wrapper {
  display: grid;
  grid-template-columns: 16px auto 16px;
  grid-template-rows: auto;
  margin-inline: -16px;
  overflow-x: auto;
}
@media (min-width: 768px) {
  .pds-table-wrapper {
    grid-template-columns: 0 auto 0;
    margin-inline: 0;
    overflow-x: revert;
  }
}
@media print {
  .pds-table-wrapper {
    margin-inline: 0;
    overflow-x: revert;
  }
}
.pds-table-wrapper::before, .pds-table-wrapper::after {
  content: "";
}
.pds-table-wrapper::-webkit-scrollbar {
  display: none;
}
.pds-table-wrapper .pds-table {
  min-width: 768px;
}
@media (min-width: 544px) {
  .pds-table-wrapper .pds-table {
    min-width: 100%;
  }
}

.pds-table {
  border-collapse: separate;
  border-top: 1px solid rgba(29, 30, 28, 0.25);
  min-width: 100%;
  table-layout: auto;
}
.pds-table tr {
  background-clip: padding-box;
}
.pds-table th,
.pds-table td {
  background-clip: padding-box;
  border-bottom: 1px solid rgba(29, 30, 28, 0.25);
  box-sizing: content-box;
  padding: 8px;
  text-align: left;
}
.pds-table th {
  background-color: #eee;
  color: rgba(29, 30, 28, 0.7);
  font-size: 13px;
  font-weight: 400;
  line-height: 1.25;
}
.pds-table tr:not(.pds-tr-child) td {
  height: 29px;
}
.pds-table tfoot th,
.pds-table tfoot td {
  border: 0;
}
.pds-table .pds-card-warm-white {
  margin-bottom: -1px;
  margin-top: -1px;
}

.pds-table-sort {
  align-items: center;
  background: transparent;
  border: 0;
  color: inherit;
  display: inline-flex;
  margin: 0;
  padding: 0;
  text-decoration: none;
  white-space: nowrap;
}

.pds-tr-child td {
  background: rgba(238, 238, 238, 0.6);
  font-size: 13px;
}
.pds-tr-child td:first-child {
  padding-left: 32px;
}

.pds-td-fit-content {
  width: 0.1%;
}

:is(td, th).pds-col-right-icon {
  padding-right: 20px;
}
:is(td, th).pds-col-right-icon:has(svg) {
  padding-right: 0;
}
:is(td, th).pds-col-right-icon svg {
  margin-left: 4px;
  translate: 0 -1px;
}

.pds-tabs-wrapper {
  box-shadow: inset 0 -1px 0 rgba(29, 30, 28, 0.25);
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  white-space: nowrap;
}
.pds-tabs-wrapper::-webkit-scrollbar {
  display: none;
}

.pds-tabs {
  align-items: center;
  display: flex;
  gap: 16px;
}
.pds-tabs ul {
  align-items: center;
  display: flex;
  gap: 16px;
}

.pds-tab {
  align-items: center;
  appearance: none;
  background: transparent;
  border: 0;
  border-bottom: 2px solid transparent;
  color: rgba(29, 30, 28, 0.7);
  display: inline-flex;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  padding: 8px 4px;
  text-decoration: none;
  white-space: nowrap;
}
.pds-tab:hover {
  border-color: #fa5d00 !important;
  color: #1d1e1c;
}
.pds-tab:focus {
  z-index: 200;
}
.pds-tab:disabled {
  border-color: transparent !important;
  color: rgba(29, 30, 28, 0.62);
}
.pds-tab.pds-tab-selected {
  border-color: #fa5d00 !important;
  color: #1d1e1c;
  font-weight: 500;
}

.pds-tab-count {
  margin: -1px 0 -1px 8px;
}

@media (min-width: 768px) {
  .pds-side-tabs-wrapper {
    box-shadow: inset 1px 0 0 rgba(29, 30, 28, 0.25);
  }
  .pds-side-tabs-wrapper .pds-tabs {
    align-items: stretch;
    flex-direction: column;
    gap: 2px;
  }
  .pds-side-tabs-wrapper .pds-tabs ul {
    align-items: stretch;
    flex-direction: column;
    gap: 2px;
  }
  .pds-side-tabs-wrapper .pds-tab {
    border-bottom: 0;
    border-left: 2px solid transparent;
    line-height: 1.25;
    min-height: 40px;
    padding: 8px 16px;
    text-align: left;
    white-space: normal;
    width: 100%;
  }
  .pds-side-tabs-wrapper .pds-tab:hover:not(.pds-tab-selected, :disabled) {
    background: rgba(29, 30, 28, 0.07);
  }
  .pds-side-tabs-wrapper .pds-tab-selected {
    background: #ffe7d9;
  }
}
@media print {
  .pds-tab-selected {
    border-width: 1px !important;
    box-shadow: none !important;
  }
}
.pds-toast {
  border-radius: 8px;
  box-shadow: 0 0.5px 1px rgba(0, 0, 0, 0.015), 0 1px 2px rgba(0, 0, 0, 0.025), 0 1.5px 3px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 4px 8px;
}
.pds-toast::before {
  display: inline-block;
  height: 20px;
  margin: -1px 4px 0 0;
  pointer-events: none;
  user-select: none;
  vertical-align: text-top;
  width: 20px;
}

.pds-toast-notice::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23376bdd' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><circle cx='12' cy='12' r='10'></circle><line x1='12' y1='8' x2='12' y2='12'></line><line x1='12' y1='16' x2='12.01' y2='16'></line></svg>");
}

.pds-toast-success::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23188433' stroke-width='2.25' stroke-linecap='round' stroke-linejoin='round'><polyline points='20 6 9 18 4 12'></polyline></svg>");
}

.pds-toast-warning::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23d99c22' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><path d='M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z'></path><line x1='12' y1='9' x2='12' y2='13'></line><line x1='12' y1='17' x2='12.01' y2='17'></line></svg>");
}

.pds-toast-danger::before {
  content: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24' fill='none' stroke='%23d92f2f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>");
}

.pds-tooltip {
  background: #1d1e1c;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font-size: 13px;
  left: 0;
  line-height: 1.25;
  max-width: 320px;
  opacity: 0;
  padding: 16px;
  position: absolute;
  text-align: left;
  top: 0;
  transition: all 0.2s;
  visibility: hidden;
  z-index: 700;
}
.pds-tooltip::before {
  content: "";
  pointer-events: none;
  position: absolute;
}

.pds-tooltip-top::before,
.pds-tooltip-bottom::before {
  border-inline: 8px solid transparent;
  left: 50%;
  margin-left: -8px;
}

.pds-tooltip-top {
  margin-top: -4px;
}
.pds-tooltip-top::before {
  border-top: 10px solid #1d1e1c;
  bottom: -10px;
}

.pds-tooltip-bottom {
  margin-top: 4px;
}
.pds-tooltip-bottom::before {
  border-bottom: 10px solid #1d1e1c;
  top: -10px;
}

.pds-tooltip-left::before,
.pds-tooltip-right::before {
  border-block: 8px solid transparent;
  margin-top: -8px;
  top: 50%;
}

.pds-tooltip-right::before {
  border-right: 10px solid #1d1e1c;
  left: -10px;
}

.pds-tooltip-left::before {
  border-left: 10px solid #1d1e1c;
  right: -10px;
}

.pds-tooltip-sm {
  max-width: 160px;
}

.pds-tooltip-md {
  max-width: 256px;
}

.pds-tooltip-visible {
  opacity: 1;
  transition: none;
  visibility: visible;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

code {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  color: #333;
  display: inline-block;
  font-family: monospace;
  font-size: 0.95em;
  padding: 0 4px;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
}

select[multiple] {
  overflow: auto;
  width: 100%;
}

::-webkit-search-decoration,
::-webkit-search-cancel-button,
::-webkit-search-results-button,
::-webkit-search-results-decoration {
  display: none;
  height: 0;
  width: 0;
}

table:empty {
  display: none;
}

@keyframes rotate-three-sixty {
  to {
    transform: rotate(360deg);
  }
}
@keyframes yellow-fade-tech {
  to {
    background-color: transparent;
  }
}
@keyframes fade-out {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
#skip-to-main {
  left: 8px;
  position: absolute;
  top: -1000px;
  z-index: 500;
}
#skip-to-main:focus {
  top: 8px;
}

body {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
}

#header {
  inset: 0;
  margin: 0;
  overflow: auto;
  padding: 16px;
  position: fixed;
  transform: translateX(-100%);
  transition: transform 0.2s;
  z-index: 700;
}

#main-nav {
  margin: 16px 0 0;
  padding: 0;
  position: relative;
  width: unset;
}

#user-nav-lg {
  display: none;
}

#main-nav-invite-person .pds-button {
  color: #fff;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.6);
  margin: 7px 8px;
  font-weight: 500;
}
#main-nav-invite-person .pds-button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.secondary-user-nav-item {
  display: none !important;
}

#view {
  background: #fff;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr max-content;
}

@media (max-width: 767px) {
  #header {
    background: #1d1e1c;
  }
  .responsive-menu-active #header {
    transform: translateX(0);
  }
  .responsive-menu-active :is(body, #view) {
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  body {
    grid-template-rows: max-content 1fr;
  }
  #header {
    height: 48px;
    overflow: revert;
    padding: 0;
    position: static;
    transform: none;
    z-index: revert;
  }
  #main-nav {
    border: 0;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 16px;
  }
  #main-nav > ul {
    display: flex;
  }
  :is(#open-mobile-menu, #close-mobile-menu, #user-nav-sm) {
    display: none !important;
  }
  #user-nav-lg {
    display: revert;
  }
}
#open-mobile-menu {
  align-items: center;
  background: transparent;
  border: 0;
  color: #fff;
  display: flex;
  font-size: 15px;
  font-weight: 500;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  width: 100%;
}

#close-mobile-menu:active {
  filter: invert(1);
}

.primary-nav-item,
.secondary-nav-item {
  border-bottom: 1px solid #616261;
  color: #fff;
  display: block;
  font-size: 20px;
  padding: 4px 0;
  text-decoration: none;
}

.primary-nav-home {
  margin-top: -2px;
}

#main-nav-settings a {
  border: 0;
}

@media (min-width: 768px) {
  :is(.primary-nav-item, .secondary-nav-item) {
    border: 0;
    line-height: 48px;
    padding: 0 8px;
    margin: 0;
  }
  :is(.primary-nav-item, .secondary-nav-item):is(:hover, .is-current) {
    background: rgba(255, 255, 255, 0.2);
  }
  :is(.primary-nav-item, .secondary-nav-item):active:focus {
    outline: none;
  }
  .primary-nav-item {
    font-size: 15px;
    font-weight: 500;
  }
  .secondary-nav-item {
    display: none;
    font-size: 15px;
    font-weight: 500;
  }
  .secondary-user-nav-item {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .secondary-nav-item {
    display: block;
  }
  .secondary-user-nav-item {
    display: none !important;
  }
}
#user-nav-sm {
  background: #343533;
  border-radius: 4px;
  margin: 16px 0;
  padding: 4px 16px;
}
#user-nav-sm a {
  border-bottom: 1px solid #4a4b49;
  color: #eee;
  display: block;
  padding: 8px 0;
  text-decoration: none;
}
#user-nav-sm li:last-child a {
  border: 0;
}
#user-nav-sm .popover-seperator {
  display: none;
}

.nav-icon-badge {
  padding: 2px;
}

#user-dropdown-toggle {
  align-items: center;
  background: transparent;
  display: inline-flex;
  gap: 8px;
}
#user-dropdown-toggle img {
  display: none;
}
#user-dropdown-toggle span {
  max-width: 104px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#user-dropdown-toggle:is(:hover, .popover-active) {
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.15));
  outline: none;
}
#user-dropdown-toggle:active {
  background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.2));
  outline: none;
}
@media (min-width: 992px) {
  #user-dropdown-toggle img {
    display: revert;
  }
}

.user-information {
  align-items: center;
  font-size: 17px;
  display: flex;
  gap: 8px;
  padding: 4px 24px 8px 16px;
  width: 100%;
}
.user-information > div {
  min-width: 0;
}
.user-information > div > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.main-nav-orange {
  background: #fa5d00;
}

.main-nav-spring {
  background: #87af23;
}

.main-nav-green {
  background: #188433;
}

.main-nav-legacy {
  background: #4294ae;
}

.main-nav-blue {
  background: #376bdd;
}

.main-nav-purple {
  background: #7d3c91;
}

.main-nav-red {
  background: #d92f2f;
}

.main-nav-lt-gray {
  background: rgba(29, 30, 28, 0.62);
}

.main-nav-gray {
  background: #1d1e1c;
}

.main-nav-behance {
  background: #1496c8;
}

#sub-nav {
  background: #fff;
  box-shadow: inset 0 -1px 0 #c6c6c6;
  max-width: 100%;
}
#sub-nav ul {
  display: flex;
  gap: 16px;
  max-width: 100%;
  overflow-x: auto;
}
#sub-nav ul::-webkit-scrollbar {
  display: none;
}
#sub-nav a {
  color: rgba(29, 30, 28, 0.7);
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.25;
  padding: 16px 4px;
  text-decoration: none;
  white-space: nowrap;
}
#sub-nav a:is(:hover, :active, .current) {
  box-shadow: inset 0 -2px 0 #fa5d00;
  color: #1d1e1c;
}
#sub-nav a.current {
  font-weight: 500;
}

@media print {
  #view {
    display: revert;
    overflow-x: revert;
    position: revert;
    transform: translateX(0) !important;
  }
}
#mobile-app-banner {
  margin: 0 16px 16px;
}
#mobile-app-banner a {
  text-decoration: none;
}
@media (min-width: 544px) {
  #mobile-app-banner {
    display: none;
  }
}

.action-bar {
  background: #fff;
  box-shadow: inset 0 -1px 0 #c6c6c6;
  padding: 16px 0;
}

#footer {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-row: 2/3;
  padding: 32px 16px 24px;
}

#footer-upgrade {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  gap: 8px;
  justify-content: center;
  margin-bottom: 8px;
  padding: 8px;
}
#footer-upgrade strong {
  font-weight: 500;
}

.footer-nav,
.footer-nav ul {
  align-items: flex-end;
  color: #737472;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  justify-content: center;
  gap: 16px;
}
@media (min-width: 768px) {
  .footer-nav,
  .footer-nav ul {
    gap: 24px;
  }
}

.footer-nav a {
  color: inherit;
  text-decoration: none;
}
.footer-nav a:hover {
  text-decoration: underline;
}

.footer-harvest-logo {
  display: flex;
  fill: rgba(29, 30, 28, 0.3);
  height: 28px;
}
.footer-harvest-logo:is(:focus, :hover) {
  fill: #a5a5a4;
}

#ada-button-frame {
  display: none;
}

/* stylelint-disable-next-line selector-id-pattern */
#errorExplanation {
  margin-bottom: 24px;
  padding: 16px;
}
#errorExplanation p {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  margin-bottom: 16px;
}
#errorExplanation ul {
  margin-left: 24px;
}
#errorExplanation li {
  list-style: disc;
}

#toast {
  height: 0;
  left: 0;
  position: sticky;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 500;
}

.modal-toast #toast {
  position: fixed;
  left: 50%;
  translate: -50%;
  z-index: calc(infinity);
}

.has-clickable-table-rows .clickable-row:hover, .has-clickable-table-rows .clickable-row.is-hovered {
  background: #deedfd;
}
.has-clickable-table-rows .clickable-row td {
  position: relative;
}
.has-clickable-table-rows .clickable-row-link {
  background-color: #fff;
  cursor: pointer;
  display: block;
  inset: 0;
  opacity: 0;
  position: absolute;
}

.search-input {
  background-image: url("../../assets/images/icons/search.svg");
  background-position: 8px 50%;
  background-repeat: no-repeat;
  padding-left: 32px;
}

.search-input-has-query {
  background-color: #fff8f1;
  border-color: #fa5d00;
}

.search-input-loading {
  background-image: url("../../assets/images/icons/icon-loading.svg");
}

form.button_to {
  display: inline-flex;
  vertical-align: top;
}

.form-loading {
  background: url("../../assets/images/loading.gif") no-repeat 0 50%;
  color: #1d1e1c;
  display: none;
  font-weight: 500;
  margin: 0;
  min-height: 16px;
  padding: 0 0 0 20px;
  vertical-align: middle;
}
.form-loading.is-visible {
  display: inline-block;
}

.input-with-suggestions {
  position: relative;
}

.input-suggestions {
  background: #fff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  opacity: 1;
  overflow-y: auto;
  padding: 8px 0;
  position: absolute;
  right: 0;
  visibility: visible;
  width: 100%;
  z-index: 400;
}
.suggestions-are-hidden .input-suggestions {
  opacity: 0;
  visibility: hidden;
}

.input-suggestion-item {
  cursor: pointer;
  padding: 8px 16px;
}
.input-suggestion-item a {
  color: inherit;
  display: block;
  padding: 8px 16px;
  text-decoration: none;
}
.input-suggestion-item.is-selected, .input-suggestion-item.is-selected a {
  background: #1d1e1c;
  color: #fff;
}
.input-suggestion-item.is-selected .pds-badge-charcoal-inverse, .input-suggestion-item.is-selected a .pds-badge-charcoal-inverse {
  background: #616261;
  color: #fff;
}
.input-suggestion-item.is-unselectable, .input-suggestion-item.is-unselectable a {
  background: transparent !important;
  color: rgba(29, 30, 28, 0.7) !important;
  cursor: default !important;
  opacity: 0.7 !important;
}

.js-validation-error-placeholder:empty {
  display: none;
}

.chosen-container.validation-error {
  background: transparent;
  color: inherit;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.chosen-container.validation-error .chosen-single {
  border: 1px solid #d92f2f;
}

/* stylelint-disable selector-class-pattern -- Rails generated classname */
.field_with_errors {
  display: block;
}

/* stylelint-enable selector-class-pattern */
/* stylelint-disable selector-class-pattern -- Rails generated classname */
/* stylelint-enable selector-class-pattern */
.popover,
.dropdown {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(29, 30, 28, 0.15);
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.015), 0 2px 4px rgba(0, 0, 0, 0.025), 0 3px 6px rgba(0, 0, 0, 0.05), 0 4px 8px rgba(0, 0, 0, 0.075), 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #1d1e1c;
  cursor: default;
  display: inline-block;
  left: 0;
  line-height: 1.4;
  margin: 2px 0 0;
  max-width: 300px;
  min-width: 100px;
  opacity: 0;
  position: absolute;
  text-align: left;
  top: 0;
  transition: opacity 0.2s, visibility 0.2s;
  visibility: hidden;
  z-index: 400;
}
.popover:focus,
.dropdown:focus {
  outline: none;
}
.popover.is-visible,
.dropdown.is-visible {
  opacity: 1;
  transition: none;
  visibility: visible;
}
.popover::before, .popover::after,
.dropdown::before,
.dropdown::after {
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  top: 0;
  width: 0;
}
.popover::before,
.dropdown::before {
  border-bottom: 10px solid #a5a5a4;
  margin: -10px 0 0 -10px;
}
.popover::after,
.dropdown::after {
  border-bottom: 10px solid #fff;
  margin: -9px 0 0 -10px;
}
.popover.is-up,
.dropdown.is-up {
  max-width: 400px;
}
.popover.is-up::before, .popover.is-up::after,
.dropdown.is-up::before,
.dropdown.is-up::after {
  bottom: -10px;
  top: auto;
}
.popover.is-up::before,
.dropdown.is-up::before {
  border-top: 10px solid #a5a5a4;
  border-bottom: none;
  margin: -10px 0 0 -10px;
}
.popover.is-up::after,
.dropdown.is-up::after {
  border-top: 10px solid #fff;
  border-bottom: none;
  margin: -9px 0 0 -10px;
}
.popover.is-up-right::before, .popover.is-up-right::after,
.dropdown.is-up-right::before,
.dropdown.is-up-right::after {
  left: 30px;
  margin-left: 0;
}
.popover.is-up-left::before, .popover.is-up-left::after,
.dropdown.is-up-left::before,
.dropdown.is-up-left::after {
  left: auto;
  margin-left: 0;
  right: 38px;
}
.popover.is-down-left,
.dropdown.is-down-left {
  left: 0 !important;
}
.popover.is-down-left::before, .popover.is-down-left::after,
.dropdown.is-down-left::before,
.dropdown.is-down-left::after {
  left: 10px;
  margin-left: 0;
}
.popover.is-down-right,
.dropdown.is-down-right {
  left: auto !important;
  right: 0;
}
.popover.is-down-right::before, .popover.is-down-right::after,
.dropdown.is-down-right::before,
.dropdown.is-down-right::after {
  left: auto;
  margin-left: 0;
  right: 10px;
}
.popover.is-right,
.dropdown.is-right {
  margin: 0 0 0 4px;
}
.popover.is-right::before, .popover.is-right::after,
.dropdown.is-right::before,
.dropdown.is-right::after {
  border-bottom: 9px solid transparent;
  border-left: 0;
  border-top: 9px solid transparent;
  left: 0;
  top: 12px;
}
.popover.is-right::before,
.dropdown.is-right::before {
  border-right: 10px solid #a5a5a4;
  margin: 0 0 0 -10px;
}
.popover.is-right::after,
.dropdown.is-right::after {
  border-right: 10px solid #fff;
  margin: 0 0 0 -9px;
}

.popover {
  padding: 10px 15px;
}
.popover > :first-child {
  margin-top: 0;
}
.popover > :last-child {
  margin-bottom: 0;
}

.popover-helper {
  background-color: #1d1e1c;
  border: 0;
  color: #fff;
  font-size: 13px;
}
.popover-helper::before {
  border-bottom-color: #1d1e1c;
}
.popover-helper.is-right::before {
  border-right-color: #1d1e1c;
}
.popover-helper::after {
  display: none;
}
.popover-helper a,
.popover-helper .pds-button-link {
  color: #86b1f1;
}

.dropdown {
  padding: 8px 0;
}
.dropdown a,
.dropdown button {
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #1d1e1c;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.25;
  padding: 8px 24px 8px 16px;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;
}
.dropdown a:hover, .dropdown a:focus, .dropdown a:active,
.dropdown button:hover,
.dropdown button:focus,
.dropdown button:active {
  background: #1d1e1c;
  color: #fff;
  outline: 0;
  text-decoration: none;
}
.dropdown a.important:hover, .dropdown a.important:focus, .dropdown a.important:active,
.dropdown button.important:hover,
.dropdown button.important:focus,
.dropdown button.important:active {
  background: #d92f2f;
}
.dropdown a:active:focus,
.dropdown button:active:focus {
  outline: none;
}
.dropdown button[disabled] {
  background: transparent !important;
  color: rgba(29, 30, 28, 0.62) !important;
  cursor: not-allowed !important;
}
.dropdown hr {
  background: #bbb;
  margin: 8px 0;
}

.popover-wrapper {
  display: inline-block;
  position: relative;
}

.popover-seperator {
  background: #c6c6c6;
  height: 1px;
  margin: 8px 0;
}

.hui-spinner {
  align-items: center;
  color: #4a4b49;
  display: inline-flex;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
  vertical-align: baseline;
}
.hui-spinner svg {
  margin-right: 4px;
}

.hui-spin {
  animation: rotate-three-sixty 0.75s steps(8) infinite;
}

.link-as-text {
  color: inherit;
  text-decoration: inherit;
}

.numbered,
.bulleted {
  margin: 1em 0 1em 2em;
}
.numbered > li,
.bulleted > li {
  margin-bottom: 0.5em;
}
.numbered > li:last-child,
.bulleted > li:last-child {
  margin-bottom: 0;
}

.numbered > li {
  list-style: decimal;
}

.bulleted > li {
  list-style: disc;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.is-visible {
  opacity: 1 !important;
  visibility: visible !important;
}

.client-document-container {
  background: #fff;
  border: 1px solid #c6c6c6;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  padding: 48px;
}
@media print {
  .client-document-container {
    border: none;
    font-size: 14px;
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.client-document-container .status-stamp {
  display: none;
  left: 50%;
  margin-top: 32px;
  position: absolute;
  transform: translateX(-50%) rotate(-15deg);
}
@media (min-width: 768px) {
  .client-document-container .status-stamp {
    display: block;
  }
}
@media print {
  .client-document-container .status-stamp:not(.pds-screen-only) {
    display: block;
  }
}

.client-document-container * {
  box-sizing: content-box;
}

.client-document-container #document-attach-files * {
  box-sizing: border-box;
}

#client-document {
  color: #1d1e1c;
  font-family: "Helvetica Neue", Helvetica, Arial, Verdana, "Nimbus Sans L", sans-serif;
  font-size: 0.75em;
  position: relative;
}

#client-document code {
  font-size: 0.9em;
  font-family: "andale mono", "lucida console", monospace;
}

#client-document p {
  margin: 0 0 1em;
}

#client-document p img.top {
  margin-top: 0;
}

#client-document blockquote {
  margin: 1.5em;
  padding: 1em;
  font-style: italic;
  font-size: 0.9em;
}

#client-document .item-description {
  word-break: break-word;
}

.client-doc-header {
  position: relative;
}

.client-doc-doc-type {
  float: right;
  font-size: 1.8em;
  font-weight: bold;
  line-height: 1.5em;
  margin: 0 0 16px;
  width: 181px;
}

.client-doc-name {
  float: left;
  margin-bottom: 30px;
}

.client-doc-name h1 {
  font-size: 3em;
  font-weight: bold;
  line-height: 1.1em;
  margin: 0;
  min-height: 80px;
  padding: 0;
  width: 300px;
}

.client-doc-name img {
  display: block;
  max-height: 200px;
  max-width: 200px;
}

.client-doc-from {
  float: right;
  margin-bottom: 30px;
}

.client-doc-for {
  float: right;
}

.client-doc-address h2 {
  color: #616261;
  float: left;
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  padding-top: 4px;
  text-align: right;
  width: 105px;
}

.client-doc-address div {
  border-left: 1px solid #bbb;
  margin-left: 115px;
  padding: 4px 0 4px 8px;
  width: 185px;
}

.client-doc-address strong {
  display: block;
  font-size: 1.2em;
  padding-bottom: 2px;
}

.client-doc-details {
  float: left;
}

.client-doc-details table {
  text-align: left;
  width: 330px;
}

.client-doc-details table td.label {
  color: #616261;
  font-size: inherit;
  padding: 4px 8px 4px 0;
  min-height: 17px;
  width: 70px;
  white-space: nowrap;
  vertical-align: top;
}

.client-doc-details table td.definition {
  border-left: 1px solid #bbb;
  min-height: 17px;
  padding: 4px 8px;
  vertical-align: top;
}

.client-doc-items {
  border-collapse: collapse;
  margin: 50px 0 0;
  width: 100%;
}

.client-doc-items th {
  border-bottom: 1px solid #bbb;
  border-right: 1px solid #bbb;
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1.1em;
  padding: 4px 8px;
  text-align: left;
  vertical-align: top;
}

.client-doc-items td {
  border-bottom: 1px solid #bbb;
  border-right: 1px solid #bbb;
  float: none;
  font-weight: normal;
  padding: 8px;
  text-align: left;
  vertical-align: top;
}

.client-doc-items th:first-child,
.client-doc-items td:first-child {
  padding-left: 4px;
}

.client-doc-items th:last-child,
.client-doc-items td:last-child {
  border-right: 0;
  padding-right: 4px;
}

.client-doc-items sup {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}

.client-doc-items .client-doc-rows tr:nth-child(even) {
  background: #f6f6f6;
}

.client-doc-items .item-type {
  width: 70px;
}

#client-document .client-doc-items .item-description p:last-child {
  margin: 0;
}

.client-doc-items .item-qty {
  text-align: right;
  width: 50px;
}

.client-doc-items .item-unit-price {
  text-align: right;
  white-space: nowrap;
  width: 75px;
}

.client-doc-items .item-amount {
  font-weight: bold;
  text-align: right;
  white-space: nowrap;
  width: 85px;
}

.client-doc-summary td {
  border: 0;
  color: #616261;
  padding: 2px 8px;
  text-align: right;
}

.client-doc-summary td.subtotal {
  font-size: 1.1em;
  font-weight: bold;
  color: #1d1e1c;
  padding-right: 4px;
  white-space: nowrap;
}

.client-doc-summary tr:first-child td {
  padding-top: 20px;
}

.client-doc-summary tr.total td {
  color: #1d1e1c;
  font-size: 1.3em;
  font-weight: bold;
  padding-top: 1.1em;
}

.client-doc-summary tr.total td.total {
  padding-right: 4px;
  white-space: nowrap;
}

.client-doc-notes {
  border-top: 1px solid #bbb;
  padding: 0 4px;
  margin: 50px 0 0;
}

* html .client-doc-notes {
  overflow-x: hidden;
}

.client-doc-notes h2 {
  font-size: 0.9em;
  font-weight: bold;
  margin: 4px 0 8px;
}

#client-document.address-on-left .client-doc-details {
  float: right;
}

#client-document.address-on-left .client-doc-details table {
  width: 307px;
}

#client-document.address-on-left .client-doc-details table td.label {
  text-align: right;
  width: 105px;
}

#client-document.address-on-left .client-doc-for {
  float: left;
}

#client-document.address-on-left .client-doc-for h2 {
  width: 70px;
  text-align: left;
}

#client-document.address-on-left .client-doc-for div {
  margin-left: 80px;
  width: 200px;
}

#client-document.address-on-left .subject-address-on-right {
  display: none;
}

#client-document.address-on-left .subject-address-on-left {
  display: block !important;
}

.subject-address-on-left {
  margin: 20px 0 0;
}

.subject-address-on-left h2 {
  width: 70px;
  text-align: left;
}

.subject-address-on-left div {
  margin-left: 80px;
  width: auto;
}

@media print {
  #client-document a:link,
  #client-document a:visited {
    background: transparent;
    font-weight: 700;
    text-decoration: underline;
  }
  #client-document .pay-online a:link,
  #client-document .pay-online a:visited {
    font-weight: normal;
    text-decoration: none;
  }
  #client-document .pay-online {
    padding-top: 4px;
  }
  #client-document [class*=status_] {
    background: none;
  }
  #client-document thead,
  #client-document tfoot {
    display: table-row-group;
  }
  .pds-table-wrapper {
    display: revert;
  }
}
.client-views-controller,
.client-estimates-controller {
  background: #eee;
}
@media print {
  .client-views-controller,
  .client-estimates-controller {
    background: transparent;
  }
}

#document-header {
  padding: 16px 0;
}

#stripe-elements-form-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

#payment-element.pds-input {
  padding-bottom: 8px;
  padding-top: 8px;
}

#document-attachments {
  font-family: "Helvetica Neue", Arial, Verdana, "Nimbus Sans L", sans-serif;
  font-size: 14px;
  margin-top: 30px;
}
#document-attachments h3 {
  color: #666;
  font-size: 14px;
  font-weight: 550;
  margin-bottom: 4px;
}
#document-attachments li {
  padding-bottom: 4px;
}

.client-document-container {
  min-height: 900px;
}

.document-tips {
  background: rgba(29, 30, 28, 0.7);
  bottom: 0;
  color: #fff;
  left: 0;
  padding: 16px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 500;
}

.footer-credit {
  font-size: 13px;
  line-height: 1;
  padding: 24px 8px 64px;
  text-align: center;
}
.footer-credit a {
  align-items: flex-end;
  display: inline-flex;
  gap: 8px;
}

@media (max-width: 544px) {
  .client-document-container {
    padding: 0;
    min-height: 0;
  }
  #client-document .client-doc-name {
    margin-bottom: 0;
    width: 100%;
  }
  #client-document .client-doc-name h1 {
    line-height: 80px;
    text-align: center;
    width: 100%;
  }
  #client-document .client-doc-name img {
    display: block;
    height: auto;
    margin: 40px auto;
    max-height: 80px;
    width: auto;
  }
  #client-document .client-doc-doc-type {
    display: block;
    text-align: center;
    width: 100%;
  }
  #client-document .client-doc-from {
    border-top: 2px solid #bbb;
  }
  #client-document .client-doc-address {
    border-bottom: 1px solid #bbb;
    margin-bottom: 0;
    width: 100%;
  }
  #client-document .client-doc-address div {
    border-left: 0;
    color: #646464;
    float: right;
    font-size: 12px;
    line-height: normal;
    margin: 0;
    padding: 12px 0;
    width: 65%;
  }
  #client-document .client-doc-address div strong {
    font-size: 14px;
    font-weight: 550;
    padding: 0;
  }
  #client-document .client-doc-address .company-address {
    display: none;
  }
  #client-document .client-doc-address h2 {
    color: #515458;
    font-size: 14px;
    font-weight: 550;
    float: left;
    line-height: normal;
    padding: 12px 0;
    text-align: left;
    width: 35%;
  }
  #client-document .client-doc-details {
    width: 100%;
  }
  #client-document .client-doc-details table {
    width: 100%;
  }
  #client-document .client-doc-details td {
    padding: 12px 0;
  }
  #client-document .client-doc-details tr {
    background: #fafafa;
    border-bottom: 1px solid #bbb;
    border-top: 1px solid #bbb;
  }
  #client-document .client-doc-details tr:first-of-type {
    border-top: 0;
  }
  #client-document .client-doc-details tr:last-of-type {
    border-bottom: 0;
  }
  #client-document .client-doc-details .label {
    color: #515458;
    font-size: 14px;
    font-weight: 550;
    width: 35%;
  }
  #client-document .client-doc-details .definition {
    border-left: 0;
    color: #646464;
    font-size: 14px;
    padding-left: 0;
    width: 65%;
  }
  #client-document .client-doc-details .due-date {
    float: left;
    width: 50%;
  }
  #client-document .client-doc-details .subject .definition {
    line-height: 20px;
  }
  #client-document .client-doc-notes {
    border: 0;
    margin: auto;
    padding: 0 0 30px;
    width: 93%;
  }
  #client-document .client-doc-notes h2 {
    color: #515458;
    font-size: 14px;
    line-height: 14px;
  }
  #client-document .client-doc-notes p {
    color: #515458;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 8px;
  }
  #client-document .client-doc-items {
    background-color: #fafafa;
    border-top: 2px solid #bbb;
    border-bottom: 2px solid #bbb;
    display: block;
    margin: 0 auto 20px;
    padding-bottom: 20px;
    width: 100%;
  }
  #client-document .client-doc-items td {
    background: transparent;
    color: #515458;
    font-size: 14px;
  }
  #client-document .client-doc-items .item-description {
    border-right: 0;
    line-height: 17px;
    padding-left: 0;
    width: 70%;
  }
  #client-document .client-doc-items .item-amount {
    border-left: 0;
    color: #515458;
    padding-right: 0;
  }
  #client-document .client-doc-items p {
    margin: 1px 0 8px;
  }
  #client-document .client-doc-items .client-doc-rows {
    display: block;
    margin: 0 auto 20px;
    width: 93%;
  }
  #client-document .client-doc-items .item-details {
    border: 0;
    border-top: 1px solid #e7e7e7;
    border-collapse: separate;
    margin-top: 10px;
    padding-top: 8px;
    display: table;
    table-layout: fixed;
  }
  #client-document .client-doc-items .item-details :is(td, th) {
    border: 0;
    border-right: 1px solid #e0e0e0;
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    padding: 0 0 3px 3%;
    width: 33%;
  }
  #client-document .client-doc-items .item-details td {
    padding: 0 3%;
    word-break: break-all;
  }
  #client-document .client-doc-items .item-details th {
    color: #979a9c;
  }
  #client-document .client-doc-items .item-details :is(td:first-of-type, th:first-of-type) {
    padding: 0 3% 0 0;
    width: 27%;
  }
  #client-document .client-doc-items .item-details :is(td:last-of-type, th:last-of-type) {
    border-right: 0;
  }
  #client-document .client-doc-summary {
    border: 0;
    display: table;
    margin: auto;
    width: 93%;
  }
  #client-document .client-doc-summary tr {
    border: 0;
    display: table-row;
    width: 100%;
  }
  #client-document .client-doc-summary tr:first-child td {
    padding-top: 0;
  }
  #client-document .client-doc-summary td {
    padding: 8px 0;
  }
  #client-document .client-doc-summary .label {
    display: table-cell;
    text-align: left;
    min-width: 45%;
    padding-right: 15px;
  }
  #client-document .client-doc-summary .subtotal {
    display: table-cell;
    text-align: right;
    min-width: 30%;
  }
  #client-document .client-doc-summary .total {
    display: table-row;
    float: none;
  }
  #client-document .client-doc-summary tr.total td {
    display: table-cell;
    padding-top: 8px;
    padding-bottom: 0;
  }
  #client-document .client-doc-summary #total-amount {
    min-width: 30%;
    text-align: right;
    float: right;
  }
  #client-document .client-doc-summary::before {
    overflow: hidden;
  }
  #client-document.address-on-left .client-doc-for div {
    width: 65%;
    float: right;
    margin-left: 0;
  }
  #client-document.address-on-left .client-doc-details table {
    width: 100%;
  }
  #client-document.address-on-left .client-doc-details table td.label {
    width: 35%;
    text-align: left;
  }
  #client-document.address-on-left .subject-address-on-left {
    border-bottom: 0;
    display: inline-block !important;
    margin-top: 0;
  }
  #client-document.address-on-left .subject-address-on-left h2 {
    width: 35%;
  }
  #client-document.address-on-left .subject-address-on-left div {
    font-size: 14px;
    margin-left: 0;
    width: 65%;
  }
  #document-attachments {
    margin: 0 auto 20px;
    width: 93%;
  }
  sup {
    vertical-align: top;
  }
}
@media (min-width: 768px) {
  .item-details {
    display: none;
  }
  p {
    display: inline;
    margin-bottom: 0;
  }
}
.is-deactivated {
  background: rgba(238, 238, 238, 0.6);
  color: rgba(29, 30, 28, 0.7);
}

.yellow-fade {
  background: #fff1cd;
  animation: 5s 2s forwards yellow-fade-tech;
}

.no-underline {
  text-decoration: none;
}